import axios from "axios";
import {api_remittances} from "@/endpoints";
import {ErrorResource, notification, notificationSuccess, saveStorage} from "@/utils";
import {TYPE_ERROR} from "@/enum/typeError";

export const invoiceStore = {
    state: () => ({
        invoiceList: [],
        invoice: {},
        paymentsGroupUSD: null,
        paymentsGroupEUR: null,
        paymentsGroupCUP: null,
        paymentsGroupMLC: null
    }),
    getters: {
        GET_INVOICE_LIST(state) {
            return state.invoiceList
        },
        GET_INVOICE(state) {
            return state.invoice
        },
        GET_PAYMENTS_GROUPS_REMITTANCES_USD(state) {
            return state.paymentsGroupUSD
        },
        GET_PAYMENTS_GROUPS_REMITTANCES_EUR(state) {
            return state.paymentsGroupEUR
        },
        GET_PAYMENTS_GROUPS_REMITTANCES_CUP(state) {
            return state.paymentsGroupCUP
        },
        GET_PAYMENTS_GROUPS_REMITTANCES_MLC(state) {
            return state.paymentsGroupMLC
        }
    },
    mutations: {
        SET_INVOICE(state, data) {
            state.invoice = data
        },
        SET_INVOICE_LIST(state, data) {
            state.invoiceList = data
        },
        SET_PAYMENTS_GROUPS_REMITTANCES_USD(state, data) {
            saveStorage('paymentGroupsUSD', data)
            state.paymentsGroupUSD = data
        },
        SET_PAYMENTS_GROUPS_REMITTANCES_CUP(state, data) {
            saveStorage('paymentGroupsCUP', data)
            state.paymentsGroupCUP = data
        },
        SET_PAYMENTS_GROUPS_REMITTANCES_EUR(state, data) {
            saveStorage('paymentGroupsEUR', data)
            state.paymentsGroupEUR = data
        },
        SET_PAYMENTS_GROUPS_REMITTANCES_MLC(state, data) {
            saveStorage('paymentGroupsMLC', data)
            state.paymentsGroupMLC = data
        }
    },
    actions: {
        SAVE_INVOICE({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_remittances.remittance, data)
                    .then((response) => {
                        notificationSuccess(`Remesa creada satifactoriamente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        UPDATE_INVOICE({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.put(`${api_remittances.remittance}${data.id}`, data.remittance)
                    .then((response) => {
                        notificationSuccess(`Remesa editada satifactoriamente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        UPDATE_INVOICE_DETAILS({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.put(`${api_remittances.details.replace('{id}', data.id)}`, data)
                    .then((response) => {
                        notificationSuccess(`Remesa editada satifactoriamente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_INVOICE({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let url = data.id ? `${api_remittances.remittance}/${data.id}` : api_remittances.remittance
                url = `${url}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}`
                url = data.query ? `${url}${data.query}` : url
                axios.get(url)
                    .then((response) => {
                        if (data.id) {
                            commit('SET_INVOICE', response.data)
                        } else {
                            commit('SET_INVOICE_LIST', response.data)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_INVOICE_DEALER({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let request = {
                    "agencyIds": data.distributorIds
                }
                axios.post(`${api_remittances.dealer}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}${data.query}`, request)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_FEES_REMITTANCE({commit}, data) {
            commit('SET_LOADING')
            return new Promise((resolve, reject) => {
                axios.get(`${api_remittances.fees}?Amount=${data.amount}&PaymentGroupId=${data.paymentGroup}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_INVOICE_PAYMENT_GROUP({commit}, id) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(api_remittances.payment_group.replace('{id}', id))
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        ACCEPT_REMITTANCE({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                let url = api_remittances.assign.replace('{id}', data.remittanceId)
                // if (data.query) {
                //     url = `${url}${data.query}`
                // }
                delete data.query
                delete data.remittanceId
                axios.put(url, data)
                    .then((response) => {
                        notification(`Remesa en distribucion`, TYPE_ERROR.INFO)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        ERROR_REMITTANCE({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.put(api_remittances.error.replace('{id}', data.id), data)
                    .then((response) => {
                        notification(`Remesa marcada como error`, TYPE_ERROR.INFO)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        CANCEL_REMITTANCE({commit}, id) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_remittances.cancel.replace('{id}', id))
                    .then((response) => {
                        notification(`Remesa cancelada`, TYPE_ERROR.INFO)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        COMPLETE_REMITTANCE({commit}, data) {
            let form = new FormData()
            form.append('identificationDocument', data.front)
            form.append('firmPicture', data.firm)
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_remittances.complete.replace('{id}', data.id), form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((response) => {
                        notification(`Remesa entregada a su receptor`, TYPE_ERROR.INFO)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
    },

}
