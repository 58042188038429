import axios from "axios";
import {api_dashboard, api_nomenclators} from "@/endpoints";
import {ErrorResource} from "@/utils";
import {AGENT_TYPE} from "@/enum/agentType";

export const nomStore = {
    state: () => ({
        countries: {},
        states: [],
        cities: [],
        paymentsGroups: [],
        agentTypes: [
            {name: 'internal_agent', value: AGENT_TYPE.INTERNAL},
            {name: 'external_agent', value: AGENT_TYPE.EXTERNAL},
        ],
        country: null
    }),
    getters: {
        GET_COUNTRY(state) {
            return state.country
        },
        GET_COUNTRY_LIST(state) {
            return state.countries
        },
        GET_STATES_LIST(state) {
            return state.states
        },
        GET_CITY_LIST(state) {
            return state.cities
        },
        GET_PAYMENTS_GROUPS(state) {
            return state.paymentsGroups
        },
        GET_AGENT_TYPE(state) {
            return state.agentTypes
        }
    },
    mutations: {
        SET_COUNTRY(state, data) {
            state.country = data
        },
        SET_COUNTRY_LIST(state, data) {
            state.countries = data
        },
        SET_STATES_LIST(state, data) {
            state.states = data
        },
        SET_CITY_LIST(state, data) {
            state.cities = data
        },
        SET_PAYMENTS_GROUPS(state, data) {
            state.paymentsGroups = data
        },
        SET_AGENT_TYPE(state, data) {
            state.agentTypes = data
        }
    },
    actions: {
        FETCH_CUBA({commit}) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(api_nomenclators.cuba)
                  .then((response) => {
                      resolve(response.data)
                  })
                  .catch((error) => {
                      resolve([])
                      ErrorResource(error)
                  }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_COUNTRY({commit}, query) {
            commit('SET_LOADING')
            let url = query ? `${api_nomenclators.country}/${query}` : api_nomenclators.country
            return new Promise(resolve => {
                axios.get(url)
                    .then((response) => {
                        if (query) {
                            commit('SET_COUNTRY', response.data)
                        } else {
                            commit('SET_COUNTRY_LIST', response.data)

                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_STATES({commit}) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(api_nomenclators.state)
                    .then((response) => {
                        commit('SET_STATES_LIST', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_STATES_BY_COUNTRY({commit}, country) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(`${api_nomenclators.state_country.replace('{country}', country)}`)
                    .then((response) => {
                        commit('SET_STATES_LIST', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_CITY({commit}, state) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(api_nomenclators.city.replace('{state}', state))
                    .then((response) => {
                        commit('SET_CITY_LIST', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_CUBAN_STATES({commit}) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(api_nomenclators.cuban_states)
                    .then((response) => {
                        resolve(response.data.results)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_CUBAN_CITY({commit}) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(api_nomenclators.cuban_cities)
                    .then((response) => {
                        resolve(response.data.results)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        EDIT_MUNICIPALITY({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.put(`${api_nomenclators.municipality}${data.id}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        EDIT_PROVINCE({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.put(`${api_nomenclators.province}${data.id}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_CITY_BY_STATE({commit}, code) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(api_nomenclators.city_state.replace('{code}', code))
                    .then((response) => {
                        commit('SET_CITY_LIST', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_PAYMENTS_GROUPS({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(`${api_nomenclators.payment_group}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}&Type=${data.type}`)
                    .then((response) => {
                        commit('SET_PAYMENTS_GROUPS', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        SAVE_PAYMENT_GROUP({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_nomenclators.payment_group, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        EDIT_PAYMENT_GROUP({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.put(`${api_nomenclators.payment_group}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        DELETE_PAYMENT_GROUP({commit}, id) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.delete(`${api_nomenclators.payment_group}${id}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(id)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_CURRENCY({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.get(`${api_nomenclators.currency}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        SAVE_CURRENCY({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_nomenclators.currency, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        EDIT_CURRENCY({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.put(`${api_nomenclators.currency}/${data.id}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        DELETE_CURRENCY({commit}, id) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.delete(`${api_nomenclators.currency}/${id}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(id)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_SALES({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.get(`${api_dashboard.sales}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },

        FETCH_SALES_DEALER({commit}) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.get(`${api_dashboard.dealer_sales}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
    },
}
