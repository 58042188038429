import {InvoicesRoutes} from './invoice'
import {BalancesRoutes} from './balances'
import {AgencyRoutes} from './agency'
import {AgentRoutes} from './agent'
import {IssuersRoutes} from './issuers'
import {NomenclatorsRoutes} from './nomenclators'
import {AccountingRoutes} from './accounting'
import {reportsRoutes} from './reports'
import {usersRoutes} from './user'

const routesComponent = {
    InvoicesRoutes,
    BalancesRoutes,
    AgencyRoutes,
    AgentRoutes,
    IssuersRoutes,
    NomenclatorsRoutes,
    AccountingRoutes,
    reportsRoutes,
    usersRoutes
}

export default routesComponent
