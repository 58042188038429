<template>
  <section>
    <breadcrumb :title="$t('dealer')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       v-model="search"
                       type="search"
                       icon="magnify"
                       icon-clickable
                       @icon-click="searchIconClick"
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
        </div>
        <div class="column is-flex is-justify-content-flex-end mr-10">
          <button @click="isModalDealerActive = !isModalDealerActive" class="button mr-20 new is-pink-light">
            <span class="mdi mdi-plus"></span>
            {{ $t('new_dealer') }}
          </button>
          <div class="mr-20" @click="generateReport">
            <b-icon type="is-purple" size="is-medium" icon="download"></b-icon>
          </div>
        </div>

      </div>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts.data"
          ref="table"
          :paginated="false"
          :per-page="desserts.itemsPerPage"
          detailed
          :show-detail-icon="false"
          detail-key="id">

        <b-table-column field="name" :label="$t('name')" v-slot="props">
          <template>
            <a class="col name" @click="props.toggleDetails(props.row)">
              {{ props.row.name }}
            </a>
          </template>
        </b-table-column>

        <b-table-column field="ci" :label="$t('ci')" sortable v-slot="props">
       <span class="ci">
         {{ props.row.identificationNumber }}
       </span>
        </b-table-column>
        <b-table-column field="email" :label="$t('email')" sortable v-slot="props">
        <span class="col email">
           {{ props.row.email }}
        </span>
        </b-table-column>

        <b-table-column field="phone" :label="$t('phone')" sortable v-slot="props">
        <span class="col common">
           {{ props.row.phoneNumber }}
        </span>
        </b-table-column>
        <b-table-column field="active" :label="$t('active')" sortable v-slot="props">
          <img v-if="props.row.isActive" class="filter" :src="checkOn">
          <img v-else class="filter" :src="checkOff">
        </b-table-column>
        <b-table-column field="municipality" :label="$t('municipality')" sortable v-slot="props">
        <span class="col common">
          {{ props.row.municipality }}
        </span>
        </b-table-column>
        <b-table-column field="province" :label="$t('province')" sortable v-slot="props">
        <span class="col common">
          {{ props.row.province }}
        </span>
        </b-table-column>

        <b-table-column :label="$t('actions')" v-slot="props">
          <div class="is-flex">
            <div @click="editDealer(props.row.id)">
              <b-icon type="is-green-accent" icon="pencil" class="mx-4 cursor-pointer"></b-icon>
            </div>
            <div class="mx-10" @click="confirmCustomDelete(props.row.id)">
              <b-icon type="is-danger" icon="trash-can-outline" class="mx-4 cursor-pointer"></b-icon>
            </div>
          </div>

        </b-table-column>

        <template #detail="props">
          <div class="columns">
            <div class="column is-3 is-flex is-flex-direction-column">
              <span class="details-text">{{ $t('address') }}</span>
              <span class="details-text">{{ $t('distribution_zone') }}</span>
              <span class="details-text">{{ $t('lic_data') }}</span>
              <span class="details-text">{{ $t('created') }}</span>
              <span class="details-text">{{ $t('updated') }}</span>
            </div>
            <div class="column is-8 is-flex is-flex-direction-column">
              <span class="details-data">{{ props.row.addressLine }}</span>
              <div class="is-flex">
                <span class="details-data">{{ props.row.distributionArea }} </span>

              </div>
              <span class="details-data">{{ props.row.license }}</span>
              <span class="details-data">{{ props.row.created }}</span>
              <span class="details-data">{{ props.row.updated }}</span>
            </div>
          </div>

        </template>
      </b-table>
      <paginate :count="desserts.totalRecords"
                :per_page="desserts.itemsPerPage"
                :current-page="desserts.currentPage"
                :total-pages="desserts.totalPages"
                @first="getLast(true)"
                @previous="getDealers(desserts.currentPage - 1,desserts.itemsPerPage)"
                @next="getDealers(desserts.currentPage + 1,desserts.itemsPerPage)"
                @last="getLast"
                @perPage="getDealers(desserts.currentPage,$event)"
                @search="getDealers($event,desserts.itemsPerPage)"
      />
      <vue-html2pdf v-if="dealerList.length"
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="false"
                    :preview-modal="true"
                    :paginate-elements-by-height="1400"
                    filename="Dealer List Report"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    pdf-format="a4"
                    pdf-orientation="portrait"
                    pdf-content-width="700px"
                    @progress="onProgress($event)"
                    @hasStartedGeneration="hasStartedGeneration()"
                    @hasGenerated="hasGenerated($event)"
                    ref="html2Pdf"
      >
        <section slot="pdf-content">
          <section class="is-flex is-justify-content-space-between is-align-items-center pl-40 mt-30">
            <div class="report-log is-flex is-align-items-center">
              <div><img style=" width: 60px" :src="logo" alt=""></div>
              <div class="is-bold ml-4">{{ $t('dealers') }}</div>
            </div>
          </section>

          <section class="content-report pl-40 mt-20">
            <div class="columns" style="border-bottom: 1px solid #000000 !important;">
              <div class="column col report head">
                {{ $t('name') }}
              </div>
              <div class="column col report head">
                {{ $t('phone') }}
              </div>
              <div class="column col report head">
                {{ $t('active') }}
              </div>
              <div class="column col report head">
                {{ $t('municipality') }}
              </div>
              <div class="column col report head">
                {{ $t('province') }}
              </div>
            </div>
            <div class="columns row-report" v-for="(item, idx) in dealerList" :key="idx">
              <div class="column col report">
                {{ item.name }}
              </div>
              <div class="column col report">
                {{ item.phoneNumber }}
              </div>
              <div class="column col report">
                <span v-if="item.isActive"> {{ $t('yes') }} </span>
                <span v-else> {{ $t('yes') }} </span>
              </div>
              <div class="column col report">
                {{ item.municipality }}
              </div>
              <div class="column col report">
                {{ item.province }}
              </div>
            </div>
          </section>

        </section>
      </vue-html2pdf>
      <b-modal
          v-model="isModalDealerActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal>
        <template #default="props">
          <modal-new-dealer :edit="edit" :dealer-id="idDealer" @reload="getDealers(desserts.currentPage,desserts.itemsPerPage)" @close="props.close"></modal-new-dealer>
        </template>
      </b-modal>
    </section>
  </section>
</template>

<script>
import ModalNewDealer from "@/components/modals/modalNewDealer";
import checkOff from '@/assets/img/checkOff.png'
import checkOn from '@/assets/img/checkOn.png'
import surface from '@/assets/img/surface.png'

import Breadcrumb from "@/components/structure/breadcrumb";
import Paginate from "@/components/list/paginate";
import {mapActions, mapGetters} from "vuex";
import {DEALER_TYPE} from "@/enum/dealerType";
import {USER_TYPE} from "@/enum/userType";
import VueHtml2pdf from 'vue-html2pdf'
import logo from "@/assets/remisario/fullcolor/imagotipo.jpg";
import {notificationSuccess} from "@/utils";

export default {
  name: "DealersComponent",
  components: {Paginate, Breadcrumb, ModalNewDealer, VueHtml2pdf},
  data() {
    return {
      dealerList: [],
      search: null,
      edit: false,
      idDealer: null,
      isModalDealerActive: false,
      filter: false,
      checkOff,
      checkOn,
      surface,
      logo,
      itemsPerPage: 0,
      currentPage: 0,
      totalPages: 0,
      totalRecords: 0,
    }
  },
  computed: {
    ...mapGetters({
      desserts: 'GET_DEALER_LIST',
      user: 'GET_USER'
    })
  },
  methods: {
    ...mapActions({
      fetchDealers: 'FETCH_DEALER',
      deleteUser: 'DELETE_USER',
    }),
    confirmCustomDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminando Distribuidor',
        message: 'Esta seguro que desea <b>eliminar</b> el distribuidor?',
        confirmText: 'Comfirmar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.deleteUser(id)
              .then(() => {
                notificationSuccess('Distribuidor eliminado');
                this.getDealers(1, 10)
              })
        }
      })
    },
    onProgress(event) {
      console.log(event);
    },
    hasGenerated(event) {
      console.log(event);
    },
    hasStartedGeneration() {
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.desserts.totalPages
      this.getDealers(page, this.desserts.itemsPerPage)
    },
    getDealers(current = 1, record = 10, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
        type: this.user.userType === USER_TYPE.DEALER ? DEALER_TYPE.DEALER : null
      };
      return new Promise(resolve => {
        this.fetchDealers(data)
            .then(value => {
              resolve(value)
            })
      })
    },
    reload() {
      window.location.reload()
    },
    searchIconClick() {
      let filter = this.search;
      if (filter && filter.length) {
        this.desserts.data = this.desserts.data.filter(function (el) {
              return el.name === filter ||
                  el.phoneNumber === filter ||
                  el.email === filter ||
                  el.identificationNumber === filter ||
                  el.province === filter ||
                  el.municipality === filter;
            }
        )
      } else {
        this.getDealers(1, 10)
      }

    },
    editDealer(id) {
      this.edit = true
      this.idDealer = id
      this.isModalDealerActive = !this.isModalDealerActive
    },
  },
  watch: {
    search: function () {
      if (this.search.length === 0) {
        this.searchIconClick()
      }
    }
  },
  created() {
    this.getDealers(1, 10)
        .then(value => {
          this.dealerList = value.data
        })
  },
}
</script>

<style scoped lang="scss">
.col {
  line-height: 32px;
  letter-spacing: 0.5px;

  &.report {
    font-size: 10px;
  }

  &.head {
    font-weight: bold;
  }
  &.name {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }

  &.ci {
    font-weight: normal;
    font-size: 16px;
    color: #0B2B46;
  }

  &.email {
    font-weight: normal;
    font-size: 16px;
    color: #0B2B46;
  }

  &.common {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #0B2B46;
  }


}

.button {
  &.new {
    width: 294px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: 0.5px;
    color: #FFFFFF;
  }

  .mdi-plus {
    font-size: 38px;
  }
}
</style>