import axios from 'axios'
import store from '@/store/index'

axios.interceptors.request.use(function (config) {
    const auth_token = localStorage.getItem('token')
    const tenant = store.getters.GET_TENANT
    if (auth_token) {
        config.headers.Authorization = `Bearer ${auth_token}`
    }
    
    if (tenant) {
        config.headers.Tenant = tenant
    }
    return config
}, function (err) {
    return Promise.reject(err)
})
