import axios from 'axios'
import {saveStorage, exitsKey, getStorage, ErrorResource, notificationSuccess} from '@/utils'
import {api_auth, api_users} from "@/endpoints";

export const securityStore = {
    state: () => ({
        tenant: 'quick-money',
        auth: exitsKey('auth') ? getStorage('auth') : false,
        profilePaymentsGroups: null,
        token: exitsKey('token') ? getStorage('token', false) : {},
        token_auth0: exitsKey('token_auth0') ? getStorage('token_auth0', false) : {},
        refresh_token: exitsKey('refresh_token') ? getStorage('refresh_token', false) : {},
        user: exitsKey('security_user') ? getStorage('security_user') : {},

    }),
    getters: {
        GET_AUTH(state) {
            return state.auth
        },
        GET_PROFILE_PAYMENTS_GROUPS(state) {
            return state.profilePaymentsGroups
        },
        GET_TENANT(state) {
            return state.tenant
        },
        GET_TOKEN(state) {
            return state.token
        },
        GET_TOKEN_AUTH0(state) {
            return state.token_auth0
        },
        GET_REFRESH_TOKEN(state) {
            return state.refresh_token
        },
        GET_USER(state) {
            return state.user
        },

    },
    mutations: {
        SET_TENANT(state, data = true) {
            saveStorage('tenant', data, false)
            state.tenant = data
        },
        SET_PROFILE_PAYMENTS_GROUPS(state, data) {
            saveStorage('profilePaymentsGroups', data)
            state.profilePaymentsGroups = data
        },
        SET_AUTH(state, data = true) {
            saveStorage('auth', data, false)
            state.auth = data
        },
        SET_TOKEN(state, data) {
            saveStorage('token', data, false)
            state.token = data
        },
        SET_TOKEN_AUTH0(state, data) {
            saveStorage('token_auth0', data, false)
            state.token_auth0 = data
        },
        SET_REFRESH_TOKEN(state, data) {
            saveStorage('refresh_token', data, false)
            state.refresh_token = data
        },
        SET_USER(state, data) {
            saveStorage('security_user', data)
            state.user = data
        }
    },
    actions: {
        LOGIN_AUTH0({commit}, data) {
            commit('SET_LOADING')
            axios.post(api_auth.token, data)
                .then((response) => {
                    commit('SET_TOKEN_AUTH0', response.data.accessToken)
                    commit('SET_TOKEN', response.data.accessToken)
                })
                .catch((error) => {
                    ErrorResource(error)
                }).finally(() => {
                commit('SET_LOADING', false)
            })
        },
        LOGIN({commit, dispatch}, data) {
            commit('SET_LOADING')
            axios.post(api_auth.login, data)
                .then((response) => {
                    commit('SET_TOKEN', response.data.token.accessToken)
                    commit('SET_AUTH', true)
                    commit('SET_USER', response.data.profile)
                    dispatch('GO_TO', 'home')
                })
                .catch((error) => {
                    // console.log('[error]', error.response);
                    ErrorResource(error)
                }).finally(() => {
                commit('SET_LOADING', false)
            })
        },
        FORGOT_PASSWORD({commit}, email) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_users.forgot_password.replace('{email}', email), {})
                    .then(() => {
                        resolve('success')
                        notificationSuccess('Se ha enviado un codigo de verificacion')
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        RESET_PASSWORD({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_users.reset_password.replace('{email}', data.email), data)
                    .then(() => {
                        resolve('success')
                        notificationSuccess('Su contraseña ha sido cambiada')
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        CHANGE_PASSWORD({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_users.change_password.replace('{email}', data.email), data)
                    .then(() => {
                        resolve('success')
                        notificationSuccess('Su contraseña ha sido cambiada')
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        REFRESH_TOKEN({commit, state}) {
            commit('SET_LOADING')
            let data = {'refresh': state.refresh_token}
            axios.post('', data)
                .then((response) => {
                    commit('SET_TOKEN', response.data.access)
                })
                .catch((error) => {
                    ErrorResource(error)
                }).finally(() => {
                commit('SET_LOADING', false)
            })
        },

        LOGOUT({commit, state}) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let data = {
                    'refresh': state.refresh_token
                }
                axios.post('', data)
                    .then((response) => {
                        resolve(response.data)
                    }).catch(reason => {
                    ErrorResource(reason)
                }).finally(() => {
                    commit('SET_LOADING', false)

                })

            })
        },
        CREATED_USER({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.post('', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })

        },

    },
}
