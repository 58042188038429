import {HTTP_STATUS_CODE} from './enum/httpStatusCode'
import {COLOR_TOAST} from './enum/colorToast'
import {SnackbarProgrammatic as Toast} from 'buefy'
import store from './store'
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import {TYPE_ERROR} from "@/enum/typeError";

Vue.use(VueToast);

export function saveStorage(key, value, object = true) {
    if (object) {
        localStorage.setItem(key, JSON.stringify(value))
    } else {
        localStorage.setItem(key, value)
    }

}

export function deleteStorage(key) {
    localStorage.removeItem(key)
}

export function getStorage(key, object = true) {
    if (localStorage.getItem(key)) {
        return object ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key)
    }
}

export function exitsKey(key) {
    return !!localStorage.getItem(key)
}

export function clearStorage() {
    localStorage.clear()
    sessionStorage.clear()
}

export async function sendToast(msg, type) {
    await Toast.open({
        message: msg,
        type: type,
        position: 'is-top',
        indefinite: false,
    })
}


export async function ErrorResourceCustom(error) {
    switch (error.response.status) {
        case HTTP_STATUS_CODE.HTTP_400_BAD_REQUEST:
            await sendToast(error.message, COLOR_TOAST.DANGER)
            break
        case HTTP_STATUS_CODE.HTTP_402_PAYMENT_REQUIRED:
            await sendToast('An error occurred in the payment process', COLOR_TOAST.DANGER)
            break
        case HTTP_STATUS_CODE.HTTP_403_FORBIDDEN:
            await sendToast('Do not have Permissions to perform this operation', COLOR_TOAST.DANGER)
            break
        case HTTP_STATUS_CODE.HTTP_404_NOT_FOUND:
            await sendToast('Resource Not Found', COLOR_TOAST.DANGER)
            break
        case HTTP_STATUS_CODE.HTTP_406_NOT_ACCEPTABLE:
            await sendToast('Insufficient amount, please recharge', COLOR_TOAST.DANGER)
            break
        case HTTP_STATUS_CODE.HTTP_401_UNAUTHORIZED:
            await sendToast(error.response.data.detail, COLOR_TOAST.DANGER)
            store.dispatch('GO_TO', 'login').then(() => {
                clearStorage()
            })
            break
        default:
            await sendToast(error, COLOR_TOAST.DANGER)
            break

    }
}

export function ErrorResource(error) {
  const msg = error && error.response ? error.response.data : 'Server Error'
  notificationError(msg)
}

export async function SuccessResponse(msg) {
    await sendToast(msg, COLOR_TOAST.DANGER)
}

export function notification(msg, type, position = 'top-right', duration = 5000) {
    Vue.$toast.open({
        message: msg,
        type: type ? type : TYPE_ERROR.SUCCESS,
        position: position,
        duration: duration
    });
}

export function notificationSuccess(msg, response = null, position = 'top-right', duration = 5000) {
    console.log(response);
    Vue.$toast.open({
        message: msg,
        type: TYPE_ERROR.SUCCESS,
        position: position,
        duration: duration
    });
}

export function notificationError(msg, position = 'top-right', duration = 5000) {

    Vue.$toast.open({
        message: msg,
        type: TYPE_ERROR.ERROR,
        position: position,
        duration: duration
    });
}


