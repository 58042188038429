const AgentGroupComponent = () => import('../views/nomenclators/AgentGroupComponent.vue')
const PaymentGroupsAgencyComponent = () => import('../views/nomenclators/PaymentGroupsAgencyComponent.vue')
const CurrenciesComponent = () => import('../views/nomenclators/CurrenciesComponent.vue')
const MunicipalityComponent = () => import('../views/nomenclators/MunicipalityComponent.vue')
const PaymentGroupsComponent = () => import('../views/nomenclators/PaymentGroupsComponent.vue')
const ProvinceComponent = () => import('../views/nomenclators/ProvinceComponent.vue')
let path = '/nomenclators/'

export const NomenclatorsRoutes = [
    {
        path: `${path}agent/group/`,
        name: 'agentGroup',
        component: AgentGroupComponent
    },

    {
        path: `${path}currencies/`,
        name: 'currencies',
        component: CurrenciesComponent
    },
    {
        path: `${path}municipality/`,
        name: 'municipality',
        component: MunicipalityComponent
    },
    {
        path: `${path}province/`,
        name: 'province',
        component: ProvinceComponent
    },
    {
        path: `${path}payments/groups/`,
        name: 'paymentGroups',
        component: PaymentGroupsComponent
    },
    {
        path: `${path}agency/groups/`,
        name: 'paymentGroupsAgency',
        component: PaymentGroupsAgencyComponent
    }
]