<template>
  <section>
    <breadcrumb :title="$t('issuer')"/>
    <div class="columns filters">
      <div class="column is-flex is-align-items-center">
        <div>
          <b-field>
            <b-input :placeholder="$t('filter_search')"
                     custom-class="search-filter"
                     v-model="search"
                     type="search"
                     icon="magnify"
                     icon-clickable
                     @icon-click="searchIconClick"
            >
            </b-input>
          </b-field>
        </div>
        <div class="ml-15">
          <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                  class="mx-4 cursor-pointer"></b-icon>
        </div>
      </div>
      <div>
        <button @click="isModalIssuerActive = !isModalIssuerActive" class="button new is-pink-light">
          <span class="mdi mdi-plus"></span>
          Nuevo Emisor
        </button>
      </div>
    </div>
    <b-table
        :data="desserts.data"
        ref="table"
        :paginated="false"
        :per-page="desserts.itemsPerPage"
        detailed
        :show-detail-icon="false"
        detail-key="email"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page">

      <b-table-column field="name" :label="$t('name')" v-slot="props">
        <template>
          <a class="col name" @click="props.toggleDetails(props.row)">
            {{ fullName(props.row) }}
          </a>
        </template>
      </b-table-column>

      <b-table-column v-if="false" field="email" :label="$t('email')" sortable v-slot="props">
       <span class="email">
         {{ props.row.email }}
       </span>
      </b-table-column>

      <b-table-column field="phone" :label="$t('phone')" sortable v-slot="props">
        <span class="col phone">
           {{ props.row.phoneNumber }}
        </span>
      </b-table-column>
      <b-table-column field="date" :label="$t('active')" sortable v-slot="props">
        <img v-if="props.row.isActive" class="filter" :src="checkOn">
        <img v-else class="filter" :src="checkOff">
      </b-table-column>
      <b-table-column field="recipients" :label="$t('recipients')" sortable v-slot="props">
        <span class="col recipients">
          {{ props.row.totalReceivers }}
        </span>
      </b-table-column>

      <b-table-column v-if="false" field="created" :label="$t('created')" sortable centered v-slot="props">
        <span class="col created"> {{ props.row.created }}</span>
      </b-table-column>

      <b-table-column :label="$t('actions')" v-slot="props">
        <div class="is-flex">
          <div v-if="false" class="mx-10">
            <b-icon type="is-green-success" icon="file-outline" class="mx-4 cursor-pointer"></b-icon>
          </div>

          <div @click="addRecipient(props.row.id)" class="mx-10">
            <b-icon type="is-green-accent" icon="account-multiple" class="mx-4 cursor-pointer"></b-icon>
          </div>
          <div @click="editIssuer(props.row.id)" class="mx-10">
            <b-icon type="is-green-accent" icon="pencil"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
          <div v-if="false" class="mx-10" @click="isModalCancelActive = !isModalCancelActive">
            <b-icon type="is-pink-light" icon="close-circle-outline cursor-pointer" class="mx-4"></b-icon>
          </div>
          <div class="mx-10" @click="confirmCustomDelete(props.row.id)">
            <b-icon type="is-danger" icon="trash-can-outline" class="mx-4 cursor-pointer"></b-icon>
          </div>
        </div>

      </b-table-column>

      <template #detail="props">
        <div class="columns">
          <div class="column is-2 is-flex is-flex-direction-column">
            <span class="details-text">{{ $t('address') }}</span>
            <span class="details-text">{{ $t('zip') }}</span>
            <span class="details-text">{{ $t('created') }}</span>
            <span class="details-text">{{ $t('updated') }}</span>
          </div>
          <div class="column is-8 is-flex is-flex-direction-column">
            <span class="details-data">{{ props.row.addressLine }}</span>
            <span class="details-data">{{ props.row.zipCode }}</span>
            <span class="details-data">{{ props.row.created }}</span>
            <span class="details-data">{{ props.row.updated }}</span>
          </div>
        </div>

      </template>
    </b-table>
    <paginate :count="desserts.totalRecords"
              :per_page="desserts.itemsPerPage"
              :current-page="desserts.currentPage"
              :total-pages="desserts.totalPages"
              @first="getLast(true)"
              @previous="getIssuers(desserts.currentPage - 1,desserts.itemsPerPage)"
              @next="getIssuers(desserts.currentPage + 1,desserts.itemsPerPage)"
              @last="getLast"
              @perPage="getIssuers(desserts.currentPage,$event)"
              @search="getIssuers($event,desserts.itemsPerPage)"
    />
    <b-modal
        v-model="isModalIssuerActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
      <template #default="props">
        <modal-new-issuer :edit="edit" :issuer-id="idIssuer"
                          @reload="getIssuers(desserts.currentPage,desserts.itemsPerPage)"
                          @close="props.close"></modal-new-issuer>
      </template>
    </b-modal>
    <b-modal
        v-model="isModalRecipientActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
      <template #default="props">
        <modal-new-recipient :issuer-id="idIssuer" :edit="edit" :recipient-id="idRecipient"
                             @reload="getIssuers(desserts.currentPage,desserts.itemsPerPage)"
                             @close="props.close"></modal-new-recipient>
      </template>
    </b-modal>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import ModalNewIssuer from "@/components/modals/modalNewIssuer";
import checkOff from '@/assets/img/checkOff.png'
import checkOn from '@/assets/img/checkOn.png'
import {mapActions, mapGetters} from "vuex";
import Paginate from "@/components/list/paginate";
import ModalNewRecipient from "@/components/modals/modalNewRecipient";
import {notificationSuccess} from "@/utils";

export default {
  name: "IssuersComponent",
  components: {ModalNewRecipient, Paginate, ModalNewIssuer, Breadcrumb},
  data() {
    return {
      search: null,
      idRecipient: null,
      edit: false,
      idIssuer: null,
      isModalIssuerActive: false,
      isModalRecipientActive: false,
      checkOn, checkOff,
    }
  },
  computed: {
    ...mapGetters({
      desserts: 'GET_ISSUER_LIST'
    })
  },
  methods: {
    ...mapActions({
      fetchIssuers: 'FETCH_ISSUER',
      deleteUser: 'DELETE_USER',
    }),
    fullName(props){
      const lastName = props.lastName? props.lastName : ''
      const secondSurname = props.secondSurname ? props.secondSurname: ''
      return `${props.name} ${lastName} ${secondSurname}`
    },
    confirmCustomDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminando Emisor',
        message: 'Esta seguro que desea <b>eliminar</b> el emisor?',
        confirmText: 'Comfirmar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.deleteUser(id)
              .then(() => {
                notificationSuccess('Emisor eliminado');
                this.getIssuers(1, 10)
              })
        }
      })
    },
    editIssuer(id) {
      this.edit = true
      this.idIssuer = id
      this.isModalIssuerActive = !this.isModalIssuerActive
    },
    addRecipient(id) {
      this.idIssuer = id
      this.isModalRecipientActive = !this.isModalRecipientActive
    },
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.desserts.totalPages
      this.getIssuers(page, this.desserts.itemsPerPage)
    },
    getIssuers(current = 1, record = 10, id = null, query = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
        query: query
      };
      this.fetchIssuers(data)
    },
    reload() {
      window.location.reload()
    },
    searchIconClick() {
      let query = this.search ? `&name=${this.search}` : null
      this.getIssuers(1, 10, null, query)
    },
  },
  watch: {
    search: function () {
      if (this.search.length === 0) {
        this.searchIconClick()
      }
    }
  },
  created() {
    this.getIssuers(1, 10)
  }
}
</script>

<style scoped lang="scss">
.col {
  line-height: 32px;
  letter-spacing: 0.5px;

  &.name {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }

  &.email {
    font-weight: normal;
    font-size: 16px;
    color: #0B2B46;
  }

  &.phone, &.recipients {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #0B2B46;
  }

  &.created {
    font-weight: bold;
    font-size: 16px;
    color: #0B2B46;
  }
}


.button {
  &.new {
    width: 294px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: 0.5px;
    color: #FFFFFF;
  }

  .mdi-plus {
    font-size: 38px;
  }
}
</style>