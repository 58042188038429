const ListAgencyComponent = () => import('../views/agencies/ListAgencyComponent.vue')
const NewAgencyComponent = () => import('../views/agencies/NewAgencyComponent.vue')
let path = '/agencies/'

export const AgencyRoutes = [
    {
        path: `${path}list`,
        name: 'listAgency',
        component: ListAgencyComponent
    },
    {
        path: `${path}new`,
        name: 'formAgency',
        component: NewAgencyComponent
    },
    {
        path: `${path}new/:id/`,
        name: 'formEditAgency',
        component: NewAgencyComponent
    }
]