import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import {securityStore} from './security'
import {agencyStore} from './agency'
import {agentStore} from './agent'
import {userStore} from './users'
import {customerStore} from './customer'
import {nomStore} from './nomenclators'
import {dealerStore} from './dealer'
import {invoiceStore} from './invoice'
import {RELATIONSHIP} from '@/enum/relationship'
import {TRANSACTION_STATUS} from "@/enum/transactionStatus";
import axios from "axios";
import {api_dealer_expenses, api_sources} from "@/endpoints";
import {ErrorResource, exitsKey, getStorage, notificationSuccess, saveStorage} from "@/utils";
import {UTILITY_TYPE} from "@/enum/utilityType";
import {DEPOSIT_TYPE} from "@/enum/depositType";
import {CURRENCY} from "@/enum/currency";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false,
        lang: exitsKey('lang') ? getStorage('lang', false) : 'es',
        is_dash: false,
        is_login: true,
        relationshipChoice: [
            {name: 'father', value: RELATIONSHIP.FATHER},
            {name: 'mother', value: RELATIONSHIP.MOTHER},
            {name: 'son', value: RELATIONSHIP.SON},
            {name: 'cousin', value: RELATIONSHIP.COUSIN},
            {name: 'uncle', value: RELATIONSHIP.UNCLE},
            {name: 'other', value: RELATIONSHIP.OTHER}
        ],
        transactionStatus: [
            {name: 'pending', value: TRANSACTION_STATUS.PENDING},
            {name: 'canceled', value: TRANSACTION_STATUS.CANCELED},
            {name: 'completed', value: TRANSACTION_STATUS.COMPLETED},
            {name: 'error', value: TRANSACTION_STATUS.ERROR},
            {name: 'shipping', value: TRANSACTION_STATUS.SHIPPING},
        ],
        utilityType: [
            {name: 'fixed', value: UTILITY_TYPE.FIXED},
            {name: 'percentage', value: UTILITY_TYPE.PERCENTAGE}
        ],
        currencies: Object.values(CURRENCY)
    },
    getters: {
        GET_LANG(state) {
            return state.lang
        },
        GET_LOADING(state) {
            return state.loading
        },
        GET_IS_DASH(state) {
            return state.is_dash
        },
        GET_IS_LOGIN(state) {
            return state.is_login
        },
        GET_UTILITY_TYPE(state) {
            return state.utilityType
        },
        GET_RELATIONSHIP_CHOICES(state) {
            return state.relationshipChoice
        },
        GET_STATUS_TRANSACTION(state) {
            return state.transactionStatus
        },
        GET_CURRENCIES(state) {
            return state.currencies
        },
    },
    mutations: {
        SET_LOADING(state, value = false) {
            state.loading = value
        },
        SET_LANG(state, value) {
            saveStorage('lang', value, false)
            state.lang = value
        },
        SET_IS_DASH(state, value = true) {
            state.is_dash = value
        },
        SET_IS_LOGIN(state, value = true) {
            state.is_login = value
        },
    },
    actions: {
        GO_TO({commit}, routerName) {
            router.push({name: routerName}).then(() => {
                if (routerName === 'login') {
                    commit('SET_AUTH', false)
                }
            })
        },
        GO_BACK() {
            router.go(-1)
        },
        DEPOSIT_BALANCE_MANAGER({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_sources.manager, data)
                    .then((response) => {
                        notificationSuccess(`Deposito Satifactorio`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        DEPOSIT_BALANCE_USER({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_sources.user.replace('{id}', data.id), data)
                    .then((response) => {
                        notificationSuccess(`Deposito Satifactorio`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        DEPOSIT_BALANCE_EXPENSES({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_dealer_expenses.replace('{id}', data.id), data)
                    .then((response) => {
                        notificationSuccess(`Deposito Satifactorio`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        DEPOSIT_BALANCE_CUSTOMER({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                let url = null;
                switch (data.customer_type) {
                    case DEPOSIT_TYPE.AGENCY:
                        url = api_sources.agency.replace('{id}', data.id)
                        break;
                    case DEPOSIT_TYPE.AGENT:
                        url = api_sources.agent.replace('{id}', data.id)
                        break;
                    case DEPOSIT_TYPE.DEALER:
                        url = api_sources.dealer.replace('{id}', data.id)
                        break;
                    case DEPOSIT_TYPE.USER:
                        url = api_sources.delivery.replace('{id}', data.id)
                        break;
                }
                axios.post(`${url}`, data)
                    .then((response) => {
                        notificationSuccess(`Deposito Satifactorio`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
    },

    modules: {
        securityStore,
        agencyStore,
        nomStore,
        agentStore,
        userStore,
        customerStore,
        dealerStore,
        invoiceStore
    }
})
