import axios from "axios";
import {
    api_agent,
    api_agent_balance_assignments,
    api_agent_exchange_rate,
    api_dashboard
} from "@/endpoints";
import {ErrorResource, notificationSuccess} from "@/utils";

export const agentStore = {
    state: () => ({
        agentList: {
            desserts: [],
            itemsPerPage: null,
            currentPage: null,
            totalPages: null,
            totalRecords: null,
        },
        agent: {}
    }),
    getters: {
        GET_AGENT_LIST(state) {
            return state.agentList
        },
        GET_AGENT(state) {
            return state.agent
        }
    },
    mutations: {
        SET_AGENT_LIST(state, data) {
            state.agentList = data
        },
        SET_AGENT(state, data) {
            state.agent = data
        }
    },
    actions: {
        SAVE_AGENT({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_agent, data)
                    .then((response) => {
                        notificationSuccess(`Agente <b>${data.name}</b> agregado correctamnente`)
                        commit('SET_AGENT_LIST', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        EDIT_AGENT({commit}, data) {
            let url = data.id ? `${api_agent}/${data.id}` : api_agent
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.put(url, data)
                    .then((response) => {
                        notificationSuccess(`Agencia <b>${data.name}</b> editado correctamnente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })

        },
        EDIT_AGENT_EXCHANGE_RATE({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.put(api_agent_exchange_rate.replace('{id}', data.id), data)
                    .then((response) => {
                        notificationSuccess(`Tasa de Cambio editada correctamnente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        DELETE_AGENT({commit,}, id) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.put(`${api_agent}/${id}`,)
                    .then((response) => {
                        notificationSuccess(`Agencia eliminado correctamnente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(id)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })

        },
        FETCH_AGENT({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let url = data.id ? `${api_agent}/${data.id}` : api_agent
                url = `${url}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}`
                url = data.agentType ? `${url}&AgentType=${data.agentType}` : url
                url = data.query ? `${url}${data.query}` : url
                axios.get(url)
                    .then((response) => {
                        if (data.id) {
                            commit('SET_AGENT', response.data)
                        } else {
                            commit('SET_AGENT_LIST', response.data)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_AGENTS_BY_AGENCY({commit}, id) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let url = id ? `${api_agent}/?AgencyId=${id}` : api_agent
                axios.get(url)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_AGENTS_REMITTANCES({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(`${api_dashboard.agent_remittance}${data}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_AGENT_BALANCE_ASSIGNMENTS({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let url = `${api_agent_balance_assignments}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}`
                url = data.id ? `${url}&AgentId=${data.id}` : url
                axios.get(url)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
    },

}