<template>
  <div class="modal-card modal-form  rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal" v-if="!edit">Nuevo Emisor</div>
          <div class="title-new-modal" v-else>Editar Emisor</div>
          <button ref="close" @click="closed" class="delete" aria-label="close"></button>
        </div>
        <section>
          <div class="card rounded-10">
            <b-tabs type="is-boxed">
              <b-tab-item>
                <template #header>
                  <span class="tag is-green-success mx-10 rounded-10">Obligatorios</span>
                </template>
                <div class="columns mt-20">
                  <div class="column is-half">
                    <b-field>
                      <b-input v-model="issuer.name" type="text" :placeholder="$t('name')"
                               custom-class="full-input"></b-input>
                    </b-field>
                    <b-field>
                      <b-input v-model="issuer.lastName" type="text" :placeholder="$t('last_name1')"
                               custom-class="full-input"></b-input>
                    </b-field>
                    <b-field>
                      <b-input v-model="issuer.secondSurname" type="text" :placeholder="$t('second_sur_name')"
                               custom-class="full-input"></b-input>
                    </b-field>

                  </div>
                  <div class="column is-half">
                    <b-field v-if="agencyView">
                      <multiselect
                          :placeholder="$t('agency')"
                          v-model="agency"
                          :custom-label="selectName"
                          open-direction="bottom"
                          :show-no-results="false"
                          :options="agenciesList">
                      </multiselect>
                    </b-field>
                    <b-field>
                      <b-input v-model="issuer.phoneNumber" type="text" :placeholder="$t('phone')"
                               custom-class="full-input"></b-input>
                    </b-field>
                    <b-field custom-class="active-btn">
                      <b-switch :value="false"
                                v-model="issuer.isActive"
                                :left-label="true"
                                size="is-medium"
                                type="is-green-success">
                        {{ $t('active') }}
                      </b-switch>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-half">
                    <b-field>
                      <multiselect
                          :placeholder="$t('country')"
                          v-model="country"
                          :custom-label="selectName"
                          open-direction="bottom"
                          :show-no-results="false"
                          :options="countryList">
                      </multiselect>
                    </b-field>
                    <b-field>
                      <multiselect
                          :placeholder="$t('state')"
                          v-model="province"
                          :custom-label="stateName"
                          open-direction="bottom"
                          :show-no-results="false"
                          :options="stateList">
                      </multiselect>
                    </b-field>
                  </div>
                  <div class="column is-half">
                    <b-field>
                      <b-input v-model="issuer.municipality" type="text" :placeholder="$t('city')" custom-class="full-input"></b-input>
                    </b-field>
                    <b-field>
                      <b-input v-model="issuer.zipCode" type="text" :placeholder="$t('zip')"
                               custom-class="full-input"></b-input>
                    </b-field>

                  </div>
                </div>
              </b-tab-item>
              <b-tab-item>
                <template #header>
                  <span class="tag is-green-accent mx-10 rounded-10">Opcionales</span>
                </template>
                <div class="columns mt-20">
                  <div class="column is-half">
                    <b-field v-if="!edit">
                      <multiselect
                          :placeholder="$t('personal_identification_type')"
                          v-model="identificationType"
                          :custom-label="setNameIdentificationType"
                          open-direction="bottom"
                          :show-no-results="false"
                          :options="identificationTypeList">
                      </multiselect>
                    </b-field>
                  </div>
                  <div class="column is-half">
                    <b-field v-if="!edit">
                      <b-input v-model="issuer.personalIdentificationNumber" type="text"
                               :placeholder="$t('personal_identification_number')"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns  mt-20">
                  <div class="column is-full">
                    <b-field>
                      <b-input v-model="issuer.addressLine" type="text"
                               :placeholder="$t('address_number', { number: 1 })"
                               custom-class="full-input"></b-input>
                    </b-field>
                  </div>

                </div>
              </b-tab-item>
            </b-tabs>
            <div class="buttons mt-40 mb-20 is-flex is-align-items-center is-justify-content-flex-end">
              <button @click="save" class="button save is-primary">Guardar</button>
            </div>
          </div>

        </section>
      </div>
    </section>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {CUSTOMER_TYPE} from "@/enum/customerType";
import {DOCUMENT_TYPE} from "@/enum/documentType";
import Multiselect from "vue-multiselect";
import {USER_TYPE} from "@/enum/userType";
import {exitsKey, getStorage, saveStorage} from "@/utils";
import {AGENT_TYPE} from "@/enum/agentType";

export default {
  name: "modalNewIssuer",
  components: {Multiselect},
  data() {
    return {
      cityList: [],
      stateList: [],
      agenciesList: [],
      country: null,
      province: null,
      municipality: null,
      locale: null,
      agency: exitsKey('agency_issuer') ? getStorage('agency_issuer') : null,
      identificationType: null,
      userTypes: USER_TYPE,
      post: true,
      agencyView: true,
      issuer: {
        id: null,
        alias: null,
        alternativeName: null,
        name: null,
        lastName: null,
        secondSurname: null,
        agencyId: null,
        personalIdentificationNumber: null,
        phoneNumber: null,
        addressLine: null,
        municipality: null,
        province: null,
        country: null,
        email: null,
        personalIdentificationType: null,
        personalIdentificationExpirationDate: null,
        personalIdentificationIssueDate: null,
        customerType: CUSTOMER_TYPE.SENDER,
        zipCode: null
      }
    }
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    issuerId: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      identificationTypeList: 'GET_IDENTIFICATION_TYPE',
      countryList: 'GET_COUNTRY_LIST',
      lang: 'GET_LANG',
      user: "GET_USER"
    })
  },
  methods: {
    ...mapActions({
      fetchCountry: 'FETCH_COUNTRY',
      fetchState: 'FETCH_STATES_BY_COUNTRY',
      fetchCity: 'FETCH_CITY',
      fetchAgency: 'FETCH_AGENCY',
      saveIssuer: 'SAVE_CUSTOMER',
      editIssuer: 'EDIT_CUSTOMER',
      fetchIssuer: 'FETCH_ISSUER',
      goBack: 'GO_BACK',
      goTo: 'GO_TO'
    }),
    selectName({name}) {
      return name
    },
    stateName({name}) {
      return name
    },
    setNameIdentificationType({value}) {
      switch (value) {
        case DOCUMENT_TYPE.CI:
          return this.lang === 'es' ? 'Carnet de Identidad' : 'DNI';
        case DOCUMENT_TYPE.SOCIAL_SECURITY:
          return this.lang === 'es' ? 'Seguridad Social' : 'Social Security';
        case DOCUMENT_TYPE.PASSPORT_CARD_US:
          return this.lang === 'es' ? 'Tarjeta de Pasaporte' : 'Passport Card';
        case DOCUMENT_TYPE.PASSPORT:
          return this.lang === 'es' ? 'Pasaporte' : 'Passport';
        case DOCUMENT_TYPE.ID_OFFICIAL:
          return this.lang === 'es' ? 'ID Oficial' : 'ID Official';
        case DOCUMENT_TYPE.DRIVER_LICENSE:
          return this.lang === 'es' ? 'Licencia de Conduccion' : 'Drive Licence';
        case DOCUMENT_TYPE.ALIEN_CARD:
          return this.lang === 'es' ? 'Tarjeta de Extranjera' : 'Alien Card';

      }
    },
    save() {
      this.issuer.agencyId = this.agency.id
      this.issuer.alias = this.issuer.name
      this.issuer.alternativeName = this.issuer.name
      if (this.post) {
        this.saveIssuer(this.issuer).then(() => {
          this.clearData()
        })
      } else {
        this.issuer.id = this.issuerId
        this.editIssuer(this.issuer).then(() => {
          this.clearData()
        })
      }

    },
    clearData() {
      this.$emit('email', this.issuer.email)
      this.issuer = {}
      this.country = null;
      this.province = null;
      this.locale = null;
      this.municipality = null;
      this.identificationType = null;
      this.$emit('close')
      this.$emit('reload')
      this.$emit('refresh')
    },
    closed() {
      this.$emit('close')
      this.$emit('reload')
      this.agency = null
      window.location.reload()
    },
    getAgencies(current = 1, record = 0, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchAgency(data)
            .then(value => {
              resolve(value)
            })
      })

    },
    getIssuers(current = 1, record = 0, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchIssuer(data)
            .then(value => {
              resolve(value)
            })
      })
    },
  },
  watch: {
    'agency': function () {
      this.issuer.agencyId = this.agency.id
      if (this.user.userType === USER_TYPE.MANAGER) {
        saveStorage('agency_issuer', this.agency)
      }
    },
    'country': async function () {
      this.issuer.country = this.country.name
      this.stateList = await this.fetchState(this.country.id)
    },
    'province': async function () {
      this.issuer.province = this.province.name
      this.cityList = await this.fetchCity(this.province.id)
    },
    'municipality': function () {
      this.issuer.municipality = this.municipality.name
    },
    'identificationType': function () {
      this.issuer.personalIdentificationType = this.identificationType.value
    },
    lang: function () {
      this.locale = this.lang === "es" ? "es-ES" : "en-US";
    }

  },
  created() {
    if (this.user.userType === USER_TYPE.AGENT && this.user.agentType === AGENT_TYPE.EXTERNAL) {
      this.agencyView = false
    }
    if (this.user.userType !== USER_TYPE.MANAGER) {
      let id = this.user.userType === USER_TYPE.AGENCY ? this.user.id : this.user.agencyId
      this.getAgencies(1, 1, id).then(value => {
        this.agency = value
        this.agenciesList.push(value)
      })
    } else {
      this.getAgencies().then(value => {
        this.agenciesList = value.data
      })
    }

    this.fetchCountry().then(() => {
      if (this.edit && this.issuerId) {
        this.post = false
        this.getIssuers(1, 1, this.issuerId).then(value => {
          this.issuer = value
          this.issuer.zipCode = value.address.zipcode
          this.issuer.email = value.address.email
          this.issuer.addressLine = value.address.line1
          this.issuer.personalIdentificationNumber = value.identityDocument.documentNumber
          this.identificationType = this.identificationTypeList.find(f => f.value === value.identityDocument.documentTypeOption)
          this.country = this.countryList.find(c => c.name === value.address.country)
          this.issuer.municipality = value.address.cityOrMunicipality
          // this.fetchState(this.country.id)
          //     .then(states => {
          //       this.province = states.find(s => s.name === value.address.stateOrProvince)
          //       this.issuer.municipality = value.address.cityOrMunicipality
          //     })
        })
      }
    })
  }
}
</script>

<style scoped lang="scss">
.tag {
  width: 111px;
  height: 24px;
  font-weight: bold;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #FFFFFF;
}


</style>