<template>
  <div class="modal-card modal-form-group rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">{{ $t('edit_utilities') }}</div>
          <button @click="closed" class="delete" aria-label="close"></button>
        </div>
        <section>
          <div class="card rounded-10">
            <b-tabs type="is-boxed">
              <b-tab-item v-for="(item, idx) in item.paymentGroups" :key="idx">
                <template #header>
                  <span class="tag is-green-success mx-10 rounded-10">{{ item.currencyTo }}</span>
                </template>
                <div class="columns is-flex">
                  <div class="column is-3">
                    <div class="boxed">
                      <div class="head is-flex is-align-items-center is-justify-content-center">
                        <b-icon type="is-white" icon="information"></b-icon>
                        <div class="px-4 info">{{ $t('info') }}</div>
                      </div>
                      <div class="body">
                        <p class="text">
                          {{ $t('payment_g_text') }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="column is-9">
                    <div class="columns">
                      <div class="column is-half">
                        <b-field :label="`${$t('name')}*`">
                          <b-input v-model="paymentGroup[item.currencyTo].groupName" type="text"></b-input>
                        </b-field>
                        <b-field :label="`${$t('percentage')}*`">
                          <b-input v-model="paymentGroup[item.currencyTo].agentCommission.rate" type="number"></b-input>
                        </b-field>
                      </div>
                      <div class="column is-half">
                        <b-field class="mt-40" custom-class="active-btn">
                          <b-switch :value="false"
                                    v-model="paymentGroup[item.currencyTo].isActive"
                                    :left-label="true"
                                    size="is-medium"
                                    type="is-green-success">
                            {{ $t('active') }}
                          </b-switch>
                        </b-field>

                      </div>
                    </div>
                    <hr>
                    <div class="columns">
                      <div class="column is-half">
                        <b-field :label="`${$t('amount_min')}`">
                          <b-input v-model="paymentGroup[item.currencyTo].agentCommission.minimalAmount" type="number"></b-input>
                        </b-field>
                        <b-field :label="`${$t('utility')}`">
                          <b-input v-model="paymentGroup[item.currencyTo].agentCommission.utilityValue" type="number"></b-input>
                        </b-field>
                      </div>
                      <div class="column is-half">
                        <b-field :label="`${$t('utility_type')}`">
                          <multiselect
                              v-model="utilityType[item.currencyTo]"
                              :custom-label="selectNameUtility"
                              open-direction="bottom"
                              :show-no-results="false"
                              :options="utilityTypeList">
                          </multiselect>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
        </section>
      </div>
      <div class="buttons mt-40 is-flex is-align-items-center is-justify-content-flex-end">
        <button @click="save" class="button save is-primary">{{ $t('save') }}</button>
      </div>
    </section>
  </div>

</template>

<script>
import Multiselect from "vue-multiselect";
import {mapActions, mapGetters} from "vuex";
import {CURRENCY} from "@/enum/currency";

export default {
  name: "modalsFormsPaymentsGroupUtilityAgent",
  components: {Multiselect},
  props: ['list', 'edit', 'item'],
  data() {
    return {
      post: true,
      name: null,
      currency: null,
      amount_min: null,
      utility_type: null,
      utility: null,
      percentage: null,
      utilityType: {USD: null, CUP: null, EUR:null},
      currencyList: Object.values(CURRENCY),
      paymentGroup: {
        USD: {
          currencyFrom: null,
          currencyTo: null,
          agentCommission:{
            rate: null,
            utilityType: null,
            minimalAmount: null,
            utilityValue: null,
          },
          isActive: false,
          groupName: null,
          type: 1,
          paymentGroupId: null
        },
        CUP: {
          currencyFrom: null,
          currencyTo: null,
          agentCommission:{
            rate: null,
            utilityType: null,
            minimalAmount: null,
            utilityValue: null,
          },
          isActive: false,
          groupName: null,
          type: 1,
          paymentGroupId: null
        },
        EUR: {
          currencyFrom: null,
          currencyTo: null,
          agentCommission:{
            rate: null,
            utilityType: null,
            minimalAmount: null,
            utilityValue: null,
          },
          isActive: false,
          groupName: null,
          type: 1,
          paymentGroupId: null
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      utilityTypeList: 'GET_UTILITY_TYPE',
      lang: 'GET_LANG',
      user: 'GET_USER'
    })
  },
  methods: {
    selectName({name}) {
      return name
    },
    selectNameUtility({value}) {
      if (value === 0) {
        return this.lang === 'es' ? 'Fija' : 'Fixed'
      }
      if (value === 1) {
        return this.lang === 'es' ? 'Porciento' : 'Percentage'
      }
    },
    ...mapActions({
      editUtilities: 'EDIT_PAYMENT_GROUP'
    }),
    save() {
      let list = []

      this.item.paymentGroups.forEach(value => {
        let pg =  {
          "currencyFrom": "USD",
          "currencyTo": null,
          "rate": null,
          "name": null,
          "utilityType": null,
          "minimalAmount": null,
          "utilityValue": null,
          "paymentGroupId": null,
          "exchangeRate": null,
          "type": 1,
          "isActive": true
        }
        pg.rate = Number(this.paymentGroup[value.currencyTo].agentCommission.rate)
        pg.minimalAmount = Number(this.paymentGroup[value.currencyTo].agentCommission.minimalAmount)
        pg.utilityValue = Number(this.paymentGroup[value.currencyTo].agentCommission.utilityValue)
        pg.paymentGroupId = this.paymentGroup[value.currencyTo].id
        pg.name =this.paymentGroup[value.currencyTo].groupName
        pg.exchangeRate = value.exchangeRate
        pg.utilityType = this.paymentGroup[value.currencyTo].utilityType
        pg.currencyTo = value.currencyTo
        pg.paymentGroupId = value.id
        // pg.isActive = this.paymentGroup[value.currencyTo].isActive
        list.push(pg)
      })
      this.editUtilities(list)
          .then(() => {
            this.closed()
          })
    },
    closed() {
      this.$emit('close')
      this.$emit('reload')
      window.location.reload()
    }
  },
  watch: {
    'utilityType.USD': function () {
      this.paymentGroup[this.currencyList[0]].utilityType = this.utilityType[this.currencyList[0]].value
    },
    'utilityType.CUP': function () {
      this.paymentGroup[this.currencyList[1]].utilityType = this.utilityType[this.currencyList[1]].value
    },
    'utilityType.EUR': function () {
      this.paymentGroup[this.currencyList[2]].utilityType = this.utilityType[this.currencyList[2]].value
    }
  },
  created() {
    if (this.edit) {
      this.post = false
      this.item.paymentGroups.forEach(value => {
        this.paymentGroup[value.currencyTo] = value
        this.utilityType[value.currencyTo] = this.utilityTypeList.find(f => f.value === value.agentCommission.utilityType)
      })

    }
  }
}
</script>

<style scoped lang="scss">
.boxed {
  height: 250px;

  .head {
    height: 33px;
    background: #28BCE8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .info {
      font-weight: 800;
      font-size: 12px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.5px;
      color: #FFFFFF;
    }
  }

  .body {
    background: rgba(20, 204, 158, 0.2);
    margin-top: -1px;
    height: 340px;
    border-top: none;
    border-left: 6px solid #28BCE8;
    border-bottom: 6px solid #28BCE8;
    border-right: 6px solid #28BCE8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .text {
      padding: 15px;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: #0B2B46;
    }
  }
}
</style>
