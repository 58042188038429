<template>
  <div id="app">
    <b-loading is-full-page v-model="isLoading" :can-cancel="false"></b-loading>
    <div class="is-flex">
      <div class="bg-menu" :class="{'d-none':isLoginPage}">
        <section class="sidebar-layout">
          <b-sidebar class="custom-sidebar"
                     position="static"
                     :mobile="mobile"
                     :expand-on-hover="expandOnHover"
                     :reduce="reduce"
                     type="is-light"
                     open
                     scroll="clip"
          >
            <div class="menu-all">
              <b-menu class="custom-menu">
                <div class="brand is-flex is-align-items-center">
                  <img style="width: 180px;" :src="logo" alt=""/>
                </div>
                <div class="custom-list-menu-all">
                  <b-menu-list>
                    <b-menu-item tag="router-link" :to="{name:'home'}" icon="home" label="Dashboard"></b-menu-item>
                    <b-menu-item icon="file-outline">
                      <template slot="label" slot-scope="props">
                        {{ $t('invoices') }}
                        <span class="is-pulled-right"
                              :class="props.expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'"></span>
                      </template>
                      <b-menu-item tag="router-link" :to="{name:'invoiceList'}"
                                   :label="$t('remittances')"></b-menu-item>
                      <b-menu-item v-if="false" tag="router-link" :to="{name:'transfers'}"
                                   :label="$t('transfers')"></b-menu-item>

                    </b-menu-item>
                    <b-menu-item icon="cash-multiple"
                                 v-if="user.userType !== userTypes.AGENT && user.userType !== userTypes.DELIVERYMAN">
                      <template slot="label" slot-scope="props">
                        {{ $t('balances') }}
                        <span class="is-pulled-right"
                              :class="props.expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'"></span>
                      </template>
                      <b-menu-item v-if="user.userType === userTypes.MANAGER" tag="router-link"
                                   :to="{name:'balanceAgency'}"
                                   :label="$t('agency_balance')"></b-menu-item>
                      <b-menu-item v-if="user.userType !== userTypes.AGENT && user.userType !== userTypes.DEALER" tag="router-link"
                                   :to="{name:'balanceAgent'}"
                                   :label="$t('agent_balance')"></b-menu-item>
                      <b-menu-item v-if="user.userType === userTypes.MANAGER || user.userType === userTypes.DEALER" tag="router-link"
                                   :to="{name:'balanceDealer'}"
                                   :label="$t('dealers')"></b-menu-item>
                      <b-menu-item v-if="user.userType === userTypes.DEALER" tag="router-link"
                                   :to="{name:'logBalancesDealer'}"
                                   :label="$t('assigned_balances')"></b-menu-item>
                    </b-menu-item>

                    <b-menu-item icon="star-outline" v-if="user.userType === userTypes.MANAGER">
                      <template slot="label" slot-scope="props">
                        {{ $t('agencies') }}
                        <span class="is-pulled-right"
                              :class="props.expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'"></span>
                      </template>
                      <b-menu-item tag="router-link" :to="{name:'formAgency'}" :label="$t('new_agency')"></b-menu-item>
                      <b-menu-item tag="router-link" :to="{name:'listAgency'}" :label="$t('list_agency')"></b-menu-item>
                    </b-menu-item>
                    <b-menu-item icon="clipboard-account"
                                 v-if="user.userType === userTypes.MANAGER || user.userType === userTypes.AGENCY">
                      <template slot="label" slot-scope="props">
                        {{ $t('agents') }}
                        <span class="is-pulled-right"
                              :class="props.expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'"></span>
                      </template>
                      <b-menu-item tag="router-link" :to="{name:'formAgent'}" :label="$t('new_agent')"></b-menu-item>
                      <b-menu-item tag="router-link" :to="{name:'listAgent'}" :label="$t('list_agent')"></b-menu-item>
                    </b-menu-item>
                    <b-menu-item v-if="user.userType !== userTypes.DEALER && user.userType !== userTypes.DELIVERYMAN" tag="router-link" :to="{name:'issuers'}"
                                 icon="account-switch"
                                 :label="$t('issuers')"></b-menu-item>
                    <b-menu-item v-if="user.userType !== userTypes.DEALER && user.userType !== userTypes.DELIVERYMAN" tag="router-link" :to="{name:'recipients'}"
                                 icon="account-multiple"
                                 :label="$t('recipients')"></b-menu-item>
                    <b-menu-item v-if="user.userType === userTypes.MANAGER || user.userType === userTypes.DEALER && user.userType !== userTypes.DELIVERYMAN"
                                 tag="router-link" :to="{name:'dealers'}"
                                 icon="motorbike"
                                 :label="$t('dealers')"></b-menu-item>
                    <b-menu-item icon="arrow-collapse"
                                 v-if="user.userType === userTypes.MANAGER">
                      <template slot="label" slot-scope="props">
                        {{ $t('nomenclators') }}
                        <span class="is-pulled-right"
                              :class="props.expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'"></span>
                      </template>
                      <b-menu-item v-if="user.userType === userTypes.MANAGER" tag="router-link"
                                   :to="{name:'currencies'}" :label="$t('currencies')"></b-menu-item>
                      <b-menu-item v-if="user.userType === userTypes.MANAGER" tag="router-link"
                                   :to="{name:'paymentGroups'}"
                                   :label="$t('payment_groups')"></b-menu-item>
                      <b-menu-item v-if="user.userType === userTypes.MANAGER" tag="router-link" :to="{name:'province'}"
                                   :label="$t('provinces')"></b-menu-item>
                      <b-menu-item v-if="user.userType === userTypes.MANAGER" tag="router-link"
                                   :to="{name:'municipality'}"
                                   :label="$t('municipalities')"></b-menu-item>
                    </b-menu-item>
                    <b-menu-item v-if="user.userType !== userTypes.DEALER && user.userType !== userTypes.DELIVERYMAN" icon="cash-usd">
                      <template slot="label" slot-scope="props">
                        {{ $t('accounting') }}
                        <span class="is-pulled-right"
                              :class="props.expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'"></span>
                      </template>
                      <b-menu-item v-if="user.userType === userTypes.MANAGER" tag="router-link"
                                   :to="{name:'dailyCredit'}"
                                   :label="$t('daily_credit')"></b-menu-item>
                      <b-menu-item tag="router-link" :to="{name:'balances'}" :label="$t('balances')"></b-menu-item>
                      <b-menu-item v-if="user.userType === userTypes.MANAGER" tag="router-link"
                                   :to="{name:'realBalances'}" :label="$t('real_balances')"></b-menu-item>
                      <b-menu-item tag="router-link" :to="{name:'sales'}" :label="$t('sales')"></b-menu-item>
                      <b-menu-item v-if="user.userType === userTypes.MANAGER" tag="router-link"
                                   :to="{name:'salesDealer'}" :label="$t('sales_dealer')"></b-menu-item>
                      <b-menu-item v-if="clusuresShow" tag="router-link" :to="{name:'closures'}" :label="$t('closures')"></b-menu-item>
                      <b-menu-item v-if="user.userType === userTypes.MANAGER"
                                   tag="router-link" :to="{name:'closuresDealer'}"
                                   :label="$t('closures_dealer')"></b-menu-item>
                    </b-menu-item>
                    <b-menu-item v-if="user.userType === userTypes.MANAGER || (user.userType === userTypes.DEALER && user.userType !== userTypes.DELIVERYMAN)"
                                 icon="chart-line">
                      <template slot="label" slot-scope="props">
                        {{ $t('reports') }}
                        <span class="is-pulled-right"
                              :class="props.expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'"></span>
                      </template>
                      <b-menu-item tag="router-link" :to="{name:'distributor'}"
                                   :label="$t('distributor')"></b-menu-item>
                      <!--                      <b-menu-item tag="router-link" :to="{name:'agencyReport'}" :label="$t('agency')"></b-menu-item>-->
                      <!--                      <b-menu-item tag="router-link" :to="{name:'distributionAgency'}"-->
                      <!--                                   :label="$t('distribution_agency')"></b-menu-item>-->
                      <!--                      <b-menu-item tag="router-link" :to="{name:'agentReport'}" :label="$t('agent')"></b-menu-item>-->
                      <!--                      <b-menu-item tag="router-link" :to="{name:'attorneyReport'}"-->
                      <!--                                   :label="$t('attorney')"></b-menu-item>-->
                      <!--                      <b-menu-item tag="router-link" :to="{name:'addresseeReport'}"-->
                      <!--                                   :label="$t('addressee')"></b-menu-item>-->
                      <!--                      <b-menu-item tag="router-link" :to="{name:'pendingDistributor'}"-->
                      <!--                                   :label="$t('pending_distributor')"></b-menu-item>-->
                      <!--                      <b-menu-item tag="router-link" :to="{name:'distributorShipping'}"-->
                      <!--                                   :label="$t('distributor_shipping')"></b-menu-item>-->
                    </b-menu-item>
                    <b-menu-item v-if="false" icon="folder-multiple" >
                      <template slot="label" slot-scope="props">
                        {{ $t('files') }}
                        <span class="is-pulled-right"
                              :class="props.expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'"></span>
                      </template>
                      <b-menu-item label="Account data"></b-menu-item>
                    </b-menu-item>
                    <b-menu-item v-if="false" icon="account" >
                      <template slot="label" slot-scope="props">
                        {{ $t('users') }}
                        <span class="is-pulled-right"
                              :class="props.expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right'"></span>
                      </template>
                      <b-menu-item  tag="router-link" :to="{name:'newUser'}" :label="$t('new_user')"></b-menu-item>
                      <b-menu-item  tag="router-link" :to="{name:'listUser'}" :label="$t('list_user')"></b-menu-item>
                    </b-menu-item>
                  </b-menu-list>
                </div>
                <div class="custom-list-menu">
                  <b-menu-list>
                    <b-menu-item tag="router-link" :to="{name:'changePassword'}" v-if="setting" icon="key-change"
                                 :label="$t('change_password')"></b-menu-item>
                    <b-menu-item @click="setting = !setting" icon="cog" :label="$t('settings')"></b-menu-item>
                    <b-menu-item tag="router-link" :to="{name:'login'}" icon="logout"
                                 :label="$t('logout')"></b-menu-item>
                  </b-menu-list>
                </div>

              </b-menu>
            </div>
          </b-sidebar>
        </section>
      </div>
      <div class="is-flex is-flex-direction-column router-view" :class="{'pt-60':isDash, 'no-login':!isLoginPage}">
        <div :class="{'d-none':isLoginPage}">
          <nav-bar v-if="navbar"/>
        </div>
        <div class="scroll">
          <router-view></router-view>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import logo from './assets/remisario/fullcolor/isotipo.png'
import NavBar from "@/components/structure/nav-bar";
import {USER_TYPE} from "@/enum/userType";
import {exitsKey, saveStorage} from "@/utils";
import {AGENT_TYPE} from "@/enum/agentType";

export default {
  name: 'App',
  components: {NavBar},
  data() {
    return {
      logo,
      navbar: exitsKey('auth'),
      setting: false,
      expandOnHover: false,
      mobile: 'reduce',
      reduce: false,
      userTypes: USER_TYPE,
      agentTypes: AGENT_TYPE,
      clusuresShow: false
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING',
      isLoginPage: 'GET_IS_LOGIN',
      isDash: 'GET_IS_DASH',
      user: 'GET_USER',
      auth: 'GET_AUTH'
    })
  },
  methods: {
    ...mapActions({
      fetchProfile: 'FETCH_PROFILE'
    })
  },
  watch: {
    navbar: function () {
    }
  },
  created() {
    if (this.user.userType === USER_TYPE.AGENT && this.user.agentType === AGENT_TYPE.INTERNAL) {
      this.clusuresShow = false
    } else {
      this.clusuresShow = true
    }
    this.setting = false
    if (this.auth) {
      this.fetchProfile().then((value) => {
        saveStorage('profile', value.paymentGroups)
      })
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "../theme/app";

.scroll {
  //overflow-y: auto !important;
}

.router-view {
  width: 100%;

}

.no-login {
  margin-left: 70px;
  margin-right: 70px;
}

#app {
  color: #2c3e50;
}

.logo {
  margin-top: 60px;
  margin-bottom: 50px;
  font-size: 50px;
  color: white;
}

//.icon {
//  margin-right: 15px;
//}

a {
  display: flex;

  i {
    font-size: 30px;
  }
}

.sub-menu {
  a {
    padding-left: 25px;
  }

  .level-2 {
    a {
      padding-left: 50px;
    }
  }

}

b-menu-item {
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #FFFFFF;
}

.custom-menu, .menu-all {
  margin: 0;
  padding: 0;
  //background: linear-gradient(180deg, #11446E 0%, #0B2B46 21.88%);
  height: 100vh;
}

.brand {
  padding-left: 3.5rem;
  position: fixed;
  //width: 250px;
}

.custom-list-menu {
  margin-top: 30px;
  padding-top: 30px;
  position: fixed;
  bottom: 30px;
}

.custom-list-menu-all {
  margin-top: 30px;
  padding-top: 160px;
}

.menu-list {
  //background: #0B2B46;
  padding-left: 41px;
}

.menu-list li ul {
  margin: 0;
  //background: #0B2B46;
  border: none;
  padding-left: 30px;
}

ul {
  li {
    padding: 2px 0px;
  }
}

.menu-list a {
  color: #1A2A42;
  padding: 0.5em 1em;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.menu-list {
  a {
    i {
      font-size: 18px;
    }

    &:hover {
      background: #28BCE8;
      color: #FFFFFF;
    }

    &.is-active {
      background: #28BCE8;
      color: #FFFFFF;
    }

    &.router-link-active {
      background: #28BCE8;
      color: #FFFFFF;
    }
  }
}

.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  // min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    // min-height: 100vh;
  }
}

.control.has-icons-left .input, .control.has-icons-left .select select {
  padding-left: 3.5em;
}

.b-sidebar {
  .sidebar-content {
    height: 100vh;
    width: 343px;
    box-shadow: none;
    overflow-y: auto;
    //background: linear-gradient(180deg, #11446E 0%, #0B2B46 21.88%);
    background: #FFFFFF;

  }
}

@media screen and (max-width: 1366px) {
  .no-login {
    margin-left: 25px;
    margin-right: 25px;
  }
  .custom-list-menu {
    margin-top: 0;
    padding-top: 0;
    position: relative;
    bottom: 0;
  }
  .custom-list-menu-all {
    padding-top: 90px;
  }
  .menu-list a {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  .b-sidebar {
    .sidebar-content {
      width: 265px;

      .logo {
        margin-top: 30px;
        margin-bottom: 25px;
      }
    }
  }
  .menu-list {
    padding-left: 21px;
  }
}

@media screen and (max-width: 1023px) {
  .b-sidebar {
    &.is-mini-mobile {
      &:not(.is-mini-expand),
      &.is-mini-expand:not(:hover) {
        .menu-list {
          li {
            a {
              span:nth-child(2) {
                display: none;
              }
            }

            ul {
              padding-left: 0;

              li {
                a {
                  display: inline-block;
                }
              }
            }
          }
        }

        .menu-label:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }

              ul {
                padding-left: 0;

                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }

          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.is-mini-expand {
  .menu-list a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

</style>
