import axios from "axios";
import {
    api_agency,
    api_agency_balance_assignments,
    api_agency_exchange_rate, api_agent,
    api_dashboard,
    api_sources
} from "@/endpoints";
import {ErrorResource, notificationSuccess} from "@/utils";
import {USER_TYPE} from "@/enum/userType";

export const agencyStore = {
    state: () => ({
        agencyList: {
            desserts: [],
            balance_assignments: [],
            itemsPerPage: null,
            currentPage: null,
            totalPages: null,
            totalRecords: null,
        },
        agency: {}
    }),
    getters: {
        GET_AGENCY_LIST(state) {
            return state.agencyList
        },
        GET_AGENCY(state) {
            return state.agency
        },
        GET_AGENCY_BALANCE_ASSIGNMENTS(state) {
            return state.balance_assignments
        }
    },
    mutations: {
        SET_AGENCY_LIST(state, data) {
            state.agencyList = data
        },
        SET_AGENCY(state, data) {
            state.agency = data
        },
        SET_AGENCY_BALANCE_ASSIGNMENTS(state, data) {
            state.balance_assignments = data
        }
    },
    actions: {
        SAVE_AGENCY({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_agency, data)
                    .then((response) => {
                        notificationSuccess(`Agencia <b>${data.name}</b> agregada correctamnente`)
                        commit('SET_AGENCY_LIST', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        EDIT_AGENCY({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.put(api_agency, data)
                    .then((response) => {
                        notificationSuccess(`Agencia <b>${data.name}</b> editada correctamnente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })

        },
        EDIT_AGENCY_EXCHANGE_RATE({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.put(api_agency_exchange_rate.replace('{id}', data.id), data)
                    .then((response) => {
                        notificationSuccess(`Tasa de Cambio editada correctamnente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        DELETE_AGENCY({commit,}, id) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.put(`${api_agency}/${id}`,)
                    .then((response) => {
                        notificationSuccess(`Agencia eliminada correctamnente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })

        },
        FETCH_AGENCY_BALANCE_ASSIGNMENTS({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let url = data.id ? `${api_agency_balance_assignments}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}&AgencyId=${data.id}` : api_agency_balance_assignments
                axios.get(url)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_AGENCY_DEBITS({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(`${api_sources.debits}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        PAY_DEBITS({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_sources.pay_debits, data)
                    .then((response) => {
                        notificationSuccess(`Pago Satifactorio`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_AGENCY({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let path = data.userType === USER_TYPE.AGENCY ? api_agent : api_agency
                let url = data.id ? `${path}/${data.id}` : path
                url = data.query ? `${url}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}${data.query}` : `${url}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}`
                axios.get(url)
                    .then((response) => {
                        if (data.id) {
                            commit('SET_AGENCY', response.data)
                        } else {
                            commit('SET_AGENCY_LIST', response.data)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_CLOSURES({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let path = data.userType === USER_TYPE.MANAGER || data.currentAgency ? api_dashboard.agency_report : api_dashboard.agent_report
                let url = data.query ? `${path}/${data.query}` : path
                axios.post(url, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_CLOSURES_DEALER({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let url = data ? `${api_dashboard.dealer_closing}/${data}` : api_dashboard.dealer_closing
                axios.get(url)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_TOTAL_REMITTANCES_DASH({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(`${api_dashboard.agency_remittance_total}${data}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_REMITTANCES_STATICS({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(`${api_dashboard.remittance_statistics}${data}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
    },

}