<template>
  <section>
    <breadcrumb :title="$t('recipients')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       v-model="search"
                       type="search"
                       icon="magnify"
                       icon-clickable
                       @icon-click="searchIconClick"
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
          <div v-if="false" @click="filter=!filter" class="ml-25">
            <b-icon type="is-purple" size="is-medium" :icon="filter_name"></b-icon>
          </div>
        </div>
        <div class="column is-flex is-justify-content-flex-end mr-10">
          <button @click="isModalRecipientActive = !isModalRecipientActive" class="button new is-pink-light">
            <span class="mdi mdi-plus"></span>
            {{ $t('new_recipient') }}
          </button>
        </div>
      </div>
      <template v-if="filter">
        <div class="columns is-flex is-flex-direction-column">
          <div class="column is-6">
            <div class="is-flex is-justify-content-space-between">
              <b-field>
                <b-input :placeholder="$t('search')"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
              <b-field>
                <b-input :placeholder="$t('issuer')"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="column is-6">
            <div class="is-flex is-justify-content-space-between">
              <b-field>
                <b-input :placeholder="$t('phone')"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
              <b-field>
                <b-input :placeholder="$t('address')"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <b-field>
              <b-select expanded :placeholder="$t('created_by')">
                <option></option>
              </b-select>
            </b-field>
          </div>
        </div>
      </template>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts.data"
          ref="table"
          :paginated="false"
          per-page="5"
          detailed
          :show-detail-icon="false"
          detail-key="id"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">

        <b-table-column field="name" :label="$t('name')" v-slot="props">
          <template>
            <a class="col name" @click="props.toggleDetails(props.row)">
              {{ fullName(props.row) }}
            </a>
          </template>
        </b-table-column>

        <b-table-column field="ci" :label="$t('ci')" sortable v-slot="props">
       <span class="ci">
         {{ props.row.identificationNumber }}
       </span>
        </b-table-column>

        <b-table-column field="phone" :label="$t('phone')" sortable v-slot="props">
        <span class="col common">
           {{ props.row.phoneNumber }}
        </span>
        </b-table-column>
        <b-table-column field="active" :label="$t('active')" sortable v-slot="props">
          <img v-if="props.row.isActive" class="filter" :src="checkOn">
          <img v-else class="filter" :src="checkOff">
        </b-table-column>
        <b-table-column field="municipality" :label="$t('municipality')" sortable v-slot="props">
        <span class="col common" v-if="props.row.municipality">
          {{ props.row.municipality }}
        </span>
          <span v-else>-</span>
        </b-table-column>
        <b-table-column field="province" :label="$t('province')" sortable v-slot="props">
        <span class="col common" v-if="props.row.province">
          {{ props.row.province }}
        </span>
          <span v-else>-</span>
        </b-table-column>

        <b-table-column v-if="false" field="issuer" :label="$t('issuer')" sortable centered v-slot="props">
          <span class="col common"> {{ props.row.issuer }}</span>
        </b-table-column>

        <b-table-column :label="$t('actions')" v-slot="props">
          <div class="is-flex">
            <div @click="editRecipient(props.row.id)">
              <b-icon type="is-green-accent" icon="pencil" class="mx-4 cursor-pointer"></b-icon>
            </div>
            <div class="mx-10" @click="confirmCustomDelete(props.row.id)">
              <b-icon type="is-danger" icon="trash-can-outline" class="mx-4 cursor-pointer"></b-icon>
            </div>
          </div>

        </b-table-column>

        <template #detail="props">
          <div class="columns">
            <div class="column is-3 is-flex is-flex-direction-column">
              <span class="details-text">{{ $t('address') }}</span>
              <span class="details-text">{{ $t('alternative_name') }}</span>
              <span class="details-text">{{ $t('alternative_last_name') }}</span>
              <span class="details-text">{{ $t('alternative_ci') }}</span>
              <span class="details-text">{{ $t('alternative_phone') }}</span>
              <span class="details-text">{{ $t('created') }}</span>
              <span class="details-text">{{ $t('updated') }}</span>
            </div>
            <div class="column is-8 is-flex is-flex-direction-column">
              <span class="details-data">{{ props.row.address }}</span>
              <span class="details-data">{{ props.row.alternative_name }}</span>
              <span class="details-data">{{ props.row.alternative_last_name }}</span>
              <span class="details-data">{{ props.row.alternative_ci }}</span>
              <span class="details-data">{{ props.row.alternative_phone }}</span>
              <span class="details-data">{{ props.row.created }}</span>
              <span class="details-data">{{ props.row.updated }}</span>
            </div>
          </div>

        </template>
      </b-table>
      <paginate :count="desserts.totalRecords"
                :per_page="desserts.itemsPerPage"
                :current-page="desserts.currentPage"
                :total-pages="desserts.totalPages"
                @first="getLast(true)"
                @previous="getRecipients(desserts.currentPage - 1,desserts.itemsPerPage)"
                @next="getRecipients(desserts.currentPage + 1,desserts.itemsPerPage)"
                @last="getLast"
                @perPage="getRecipients(desserts.currentPage,$event)"
                @search="getRecipients($event,desserts.itemsPerPage)"
      />
      <b-modal
          v-model="isModalRecipientActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal>
        <template #default="props">
          <modal-new-recipient :edit="edit" :recipient-id="idRecipient"
                               @reload="getRecipients(desserts.currentPage,desserts.itemsPerPage)"
                               @close="props.close"></modal-new-recipient>
        </template>
      </b-modal>
    </section>
  </section>
</template>


<script>
import checkOff from '@/assets/img/checkOff.png'
import checkOn from '@/assets/img/checkOn.png'
import surface from '@/assets/img/surface.png'

import Breadcrumb from "@/components/structure/breadcrumb";
import ModalNewRecipient from "@/components/modals/modalNewRecipient";
import {mapActions, mapGetters} from "vuex";
import Paginate from "@/components/list/paginate";
import {notificationSuccess} from "@/utils";

export default {
  name: "RecipientsComponent",
  components: {Paginate, ModalNewRecipient, Breadcrumb},
  data() {
    return {
      search: null,
      isModalRecipientActive: false,
      filter: false,
      idRecipient: null,
      edit: false,
      filter_name: 'filter-outline',
      checkOff,
      checkOn,
      surface,

    }
  },
  computed: {
    ...mapGetters({
      desserts: 'GET_RECIPIENT_LIST'
    })
  },
  methods: {
    ...mapActions({
      fetchRecipients: 'FETCH_RECIPIENT',
      deleteUser: 'DELETE_USER',
    }),
    fullName(props){
      const lastName = props.lastName? props.lastName : ''
      const secondSurname = props.secondSurname ? props.secondSurname: ''
      return `${props.name} ${lastName} ${secondSurname}`
    },
    confirmCustomDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminando Destinatario',
        message: 'Esta seguro que desea <b>eliminar</b> el destinatario?',
        confirmText: 'Comfirmar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.deleteUser(id)
              .then(() => {
                notificationSuccess('Destinatario eliminado');
                this.getRecipients(1, 10)
              })
        }
      })
    },
    editRecipient(id) {
      this.edit = true
      this.idRecipient = id
      this.isModalRecipientActive = !this.isModalRecipientActive
    },
    reload() {
      window.location.reload()
    },
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.desserts.totalPages
      this.getRecipients(page, this.desserts.itemsPerPage)
    },
    getRecipients(current, record, id = null, query = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
        query: query
      };
      this.fetchRecipients(data)
    },
    searchIconClick() {
      let query = this.search ? `&name=${this.search}` : null
      this.getRecipients(1, 10, null, query)
    },
  },

  created() {
    this.getRecipients(1, 10)
  },
  watch: {
    search: function () {
      if (this.search.length === 0) {
        this.searchIconClick()
      }
    },
    filter: function () {
      this.filter_name = this.filter ? 'filter-remove-outline' : 'filter-outline'
    }
  }
}
</script>

<style scoped lang="scss">
.col {
  line-height: 32px;
  letter-spacing: 0.5px;

  &.name {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }

  &.ci {
    font-weight: normal;
    font-size: 16px;
    color: #0B2B46;
  }

  &.common {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #0B2B46;
  }


}

.button {
  &.new {
    width: 294px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: 0.5px;
    color: #FFFFFF;
  }

  .mdi-plus {
    font-size: 38px;
  }
}
</style>