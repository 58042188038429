const ListAgentComponent = () => import('../views/agents/ListAgentComponent.vue')
const NewAgentComponent = () => import('../views/agents/NewAgentComponent.vue')
let path = '/agent/'

export const AgentRoutes = [
    {
        path: `${path}list`,
        name: 'listAgent',
        component: ListAgentComponent
    },
    {
        path: `${path}new`,
        name: 'formAgent',
        component: NewAgentComponent
    },  {
        path: `${path}new/:agencyId`,
        name: 'formAgentByAgency',
        component: NewAgentComponent
    },
    {
        path: `${path}form/:id`,
        name: 'formEditAgent',
        component: NewAgentComponent
    }
]