import axios from "axios";
import {
    api_dealer,
    api_dealer_balances,
    api_dealer_daily_credit_report,
    api_dealer_transfer,
    api_delivery_man
} from "@/endpoints";
import {ErrorResource, notificationSuccess} from "@/utils";
import {DEALER_TYPE} from "@/enum/dealerType";

export const dealerStore = {
    state: () => ({
        dealerList: {
            desserts: [],
            itemsPerPage: null,
            currentPage: null,
            totalPages: null,
            totalRecords: null,
        },
        dealer: {}
    }),
    getters: {
        GET_DEALER_LIST(state) {
            return state.dealerList
        },
        GET_DEALER(state) {
            return state.dealer
        }
    },
    mutations: {
        SET_DEALER_LIST(state, data) {
            state.dealerList = data
        },
        SET_DEALER(state, data) {
            state.dealer = data
        }
    },
    actions: {
        TRANSFER_DEALER({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_dealer_transfer, data)
                    .then((response) => {
                        notificationSuccess(`Trasferencia Satifactoria`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        SAVE_DEALER({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                let url = data.type === DEALER_TYPE.DEALER ? api_dealer : api_delivery_man
                axios.post(url, data)
                    .then((response) => {
                        notificationSuccess(`Distribuidor <b>${data.name}</b> agregado correctamnente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        EDIT_DEALER({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                let url = data.type === DEALER_TYPE.DEALER ? api_dealer : api_delivery_man
                axios.put(`${url}/${data.id}`, data)
                    .then((response) => {
                        notificationSuccess(`Distribuidor <b>${data.name}</b> editado correctamnente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })

        },
        DELETE_DEALER({commit,}, id) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.put(`${api_dealer}/${id}`,)
                    .then((response) => {
                        notificationSuccess(`Distribuidor  eliminado correctamnente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(id)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })

        },
        FETCH_DEALER({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let path = data.type === DEALER_TYPE.DEALER ? api_delivery_man : api_dealer
                path = data.id ? `${path}/${data.id}` : path
                let url = `${path}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}`
                url = data.query ? `${url}${data.query}` : url
                axios.get(url)
                    .then((response) => {
                        if (data.id) {
                            commit('SET_DEALER', response.data)
                        } else {
                            commit('SET_DEALER_LIST', response.data)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_DEALER_QUERY({commit}, query) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(`${api_dealer}?ReceiverId=${query}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_DEALER_BALANCES({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(`${api_dealer_balances}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_DEALER_DAILY_CREDIT({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let url = data.dateTime ? `${api_dealer_daily_credit_report}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}&dateTime=${data.dateTime}` : `${api_dealer_daily_credit_report}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}`
                url = data.query ? `${url}${data.query}` : url
                axios.get(url)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
    },

}