import axios from "axios";
import {api_customer} from "@/endpoints";
import {ErrorResource, notificationSuccess} from "@/utils";
import {DOCUMENT_TYPE} from "@/enum/documentType";

export const customerStore = {
    state: () => ({
        recipientsList: {
            desserts: [],
            itemsPerPage: null,
            currentPage: null,
            totalPages: null,
            totalRecords: null,
        },
        recipient: {},
        issuerList: {
            desserts: [],
            itemsPerPage: null,
            currentPage: null,
            totalPages: null,
            totalRecords: null,
        },
        issuer: {},
        identificationType: [
            {name: 'ci', value: DOCUMENT_TYPE.CI},
            {name: 'alien_card', value: DOCUMENT_TYPE.ALIEN_CARD},
            {name: 'drive_license', value: DOCUMENT_TYPE.DRIVER_LICENSE},
            {name: 'id_official', value: DOCUMENT_TYPE.ID_OFFICIAL},
            {name: 'passport', value: DOCUMENT_TYPE.PASSPORT},
            {name: 'passport_card_us', value: DOCUMENT_TYPE.PASSPORT_CARD_US},
            {name: 'social_security', value: DOCUMENT_TYPE.SOCIAL_SECURITY},
        ]
    }),
    getters: {
        GET_RECIPIENT_LIST(state) {
            return state.recipientsList
        },
        GET_RECIPIENT(state) {
            return state.recipient
        },
        GET_ISSUER_LIST(state) {
            return state.issuerList
        },
        GET_ISSUER(state) {
            return state.issuer
        },
        GET_IDENTIFICATION_TYPE(state) {
            return state.identificationType
        }
    },
    mutations: {
        SET_ISSUER_LIST(state, data) {
            state.issuerList = data
        },
        SET_ISSUER(state, data) {
            state.issuer = data
        },
        SET_RECIPIENT_LIST(state, data) {
            state.recipientsList = data
        },
        SET_RECIPIENT(state, data) {
            state.issuer = data
        }
    },
    actions: {
        FETCH_RECIPIENT({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let url = data.id ? `${api_customer.details.replace('{id}', data.id)}` : api_customer.get_receiver
                url = data.query ? `${url}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}${data.query}` : `${url}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}`
                axios.get(`${url}`)
                    .then((response) => {
                        if (data.id) {
                            commit('SET_RECIPIENT', response.data)
                        } else {
                            commit('SET_RECIPIENT_LIST', response.data)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_RECIPIENT_BY_ISSUER({commit}, id) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(`${api_customer.get_receiver}?senderId=${id}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_ISSUER({commit}, data) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                let url = data.id ? `${api_customer.details.replace('{id}', data.id)}` : api_customer.get_sender
                url = data.query ? `${url}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}${data.query}` : `${url}?CurrentPage=${data.currentPage}&RecordsPerPage=${data.recordsPerPage}`
                axios.get(`${url}`)
                    .then((response) => {
                        if (data.id) {
                            commit('SET_ISSUER', response.data)
                        } else {
                            commit('SET_ISSUER_LIST', response.data)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        FETCH_ISSUER_BY_AGENCY({commit}, id) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(`${api_customer.get_sender}?agencyId=${id}`)
                    .then((response) => {
                        commit('SET_ISSUER_LIST', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        SAVE_CUSTOMER({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.post(api_customer.customer, data)
                    .then((response) => {
                        notificationSuccess(`Usuario <b>${data.name}</b> agregado correctamnente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        EDIT_CUSTOMER({commit}, data) {
            return new Promise(resolve => {
                commit('SET_LOADING')
                axios.put(`${api_customer.customer}${data.id}/`, data)
                    .then((response) => {
                        notificationSuccess(`Usuario <b>${data.name}</b> editado correctamnente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(data)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })

        },
        DELETE_CUSTOMER({commit,}, id) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.put(`${api_customer.customer}/${id}`,)
                    .then((response) => {
                        notificationSuccess(`Usuario eliminado correctamnente`)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve(id)
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })

        },

    },

}