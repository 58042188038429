<template>
  <section class="is-flex">
    <div class="account is-flex is-align-items-center">
      <div class="columns">
        <div class="column is-8 is-flex is-justify-content-flex-end centered width-auto">
          <div class="is-flex is-flex-direction-column is-align-items-flex-start ml-20">
            <div class="username">{{ user.name }}</div>
            <div class="agent-title">{{ getAgentTitle(user.userType) }}</div>
          </div>
        </div>
        <div class="column is-3 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <avatar class="avatar-size" color="#FFFFFF" backgroundColor="#FDC4D1" :size="60"
                  :username="user.name"
                  :src="user.profilePicture"/>
        </div>
      </div>
    </div>
    <b-dropdown
        position="is-bottom-left"
        append-to-body
        aria-role="menu"
        trap-focus
    >
      <template #trigger>
      <span
          class="navbar-item"
          role="button">
        <b-icon icon="chevron-down"></b-icon>
      </span>
      </template>
      <b-dropdown-item
          aria-role="menu-item"
          :focusable="false"
          custom
          paddingless>
        <router-link :to="{name:'login'}">
          <div class="title-collapse is-flex is-align-items-center">
            <b-icon icon="account-star"></b-icon>
            <span class="ml-3">{{ $t('profile') }}</span>
          </div>
        </router-link>
        <p class="title-collapse">

        </p>
      </b-dropdown-item>
      <b-dropdown-item v-if="show_utilities"
                       aria-role="menu-item"
                       :focusable="false"
                       custom
                       paddingless>
        <div @click="editGroup" class="title-collapse is-flex is-align-items-center">
          <b-icon icon="cogs"></b-icon>
          <span class="ml-3"> {{ $t('utilities') }}</span>
        </div>
      </b-dropdown-item>
      <b-dropdown-item
          aria-role="menu-item"
          :focusable="false"
          custom
          paddingless>
        <b-collapse
            animation="slide"
            :open="isOpen === 1"
            @open="isOpen = 1">
          <template #trigger="props">
            <div
                role="button"
            >

              <div class="title-collapse is-flex is-align-items-center">
                <b-icon icon="key-change"></b-icon>
                <span class="ml-3">  {{ $t('change_password') }}</span>
              </div>
              <a v-if="props.icon">
                <b-icon
                    :icon="props.open ? 'menu-down' : 'menu-up'">
                </b-icon>
              </a>
            </div>
          </template>
          <div class="content">
            <form>
              <div class="modal-card" style="width:300px;">
                <section class="modal-card-body">
                  <b-field>
                    <b-input :placeholder="$t('email')"
                             type="email"
                             required
                             :validation-message="$t('invalid_email')"
                             v-model="change.email"
                             icon="account"
                             icon-clickable>
                    </b-input>
                  </b-field>
                  <b-field class="mt-2">
                    <b-input :placeholder="$t('old_password')"
                             type="password"
                             v-model="change.oldPassword"
                             icon="lock-outline"
                             required
                             :validation-message="$t('password_required')"
                             password-reveal
                             icon-clickable>
                    </b-input>
                  </b-field>

                  <b-field class="mt-2">
                    <b-input :placeholder="$t('new_password')"
                             type="password"
                             v-model="change.newPassword"
                             icon="lock-outline"
                             required
                             :validation-message="$t('password_required')"
                             password-reveal
                             icon-clickable>
                    </b-input>
                  </b-field>
                </section>
                <footer class="modal-card-foot">
                  <b-button @click="changePassword" class="btn btn-insert" type="is-primary">{{ $t('send') }}</b-button>
                </footer>
              </div>
            </form>
          </div>
        </b-collapse>
      </b-dropdown-item>
      <b-dropdown-item
          aria-role="menu-item"
          :focusable="false"
          custom
          paddingless>

        <router-link :to="{name:'login'}">
          <div class="title-collapse is-flex is-align-items-center">
            <b-icon icon="logout"></b-icon>
            <span class="ml-3"> {{ $t('logout') }}</span>
          </div>
        </router-link>
      </b-dropdown-item>
    </b-dropdown>

    <b-modal
        v-model="isModalPaymentGroupsUtilitiesActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
      <template #default="props">
        <modals-forms-payments-group-utility @reload="reload" :item="profile" :edit="edit"
                                             @close="props.close"></modals-forms-payments-group-utility>
      </template>
    </b-modal>
    <b-modal
        v-model="isModalPaymentGroupsUtilitiesAgentActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
      <template #default="props">
        <modals-forms-payments-group-utility-agent @reload="reload" :item="profile" :edit="edit"
                                                   @close="props.close"></modals-forms-payments-group-utility-agent>
      </template>
    </b-modal>
  </section>


</template>

<script>
import Avatar from 'vue-avatar'
import {mapActions, mapGetters} from 'vuex'
import {USER_TYPE} from "@/enum/userType";
import ModalsFormsPaymentsGroupUtility from "@/components/modals/modalsFormsPaymentsGroupUtilities";
import {exitsKey, getStorage} from "@/utils";
import ModalsFormsPaymentsGroupUtilityAgent from "@/components/modals/modalsFormsPaymentsGroupUtilitiesAgent";

export default {
  name: 'Account',
  components: {ModalsFormsPaymentsGroupUtilityAgent, ModalsFormsPaymentsGroupUtility, Avatar},
  data() {
    return {
      edit: true,
      userTypes: USER_TYPE,
      isModalPaymentGroupsUtilitiesActive: false,
      isModalPaymentGroupsUtilitiesAgentActive: false,
      isOpen: null,
      change: {
        email: null,
        oldPassword: null,
        newPassword: null
      }
    }
  },
  props: {
    show_utilities: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'GET_USER',
      lang: 'GET_LANG',
      profile: 'GET_PROFILE'
    })
  },
  methods: {
    ...mapActions({
      doChange: 'CHANGE_PASSWORD',
      fetchProfile: 'FETCH_PROFILE'
    }),
    editGroup() {
      if (this.profile.userType === USER_TYPE.AGENT) {
        this.isModalPaymentGroupsUtilitiesAgentActive = !this.isModalPaymentGroupsUtilitiesAgentActive
      } else {
        this.isModalPaymentGroupsUtilitiesActive = !this.isModalPaymentGroupsUtilitiesActive
      }
    },
    changePassword() {
      this.doChange(this.change)
          .then(() => {
            this.change.email = null
            this.change.oldPassword = null
            this.change.newPassword = null
            this.isOpen = null
          })
    },
    getAgentTitle(type) {
      switch (type) {
        case USER_TYPE.MANAGER:
          return this.lang === 'es' ? 'Manager' : 'Manager'
        case USER_TYPE.AGENCY:
          return this.lang === 'es' ? 'Agencia' : 'Agency'
        case USER_TYPE.AGENT:
          return this.lang === 'es' ? `Agente ${this.user.agencyName}` : `Agent ${this.user.agencyName}`
      }
    },
    reload() {
      window.location.reload()
    }
  },
  created() {

    if (exitsKey('auth')) {
      const auth = getStorage('auth', false)
      if (auth) {
        this.fetchProfile()
            .then(() => {
            })
      }

    }

  }

}
</script>

<style scoped lang="scss">

.account {
  height: 97px;
  border-radius: 19px;
  letter-spacing: 0.5px;

  .username {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }

  .agent-title {
    font-size: 15px;
    line-height: 25px;
    color: #9098B1;
  }

}

@media screen and (max-width: 1366px) {
  .avatar-size {
    width: 40px !important;
    height: 40px !important;
    font-size: 18px !important;
  }

  .account {
    margin-top: 0;
    height: 60px;

    .username {
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
    }

    .agent-title {
      font-size: 11px;
      line-height: 25px;
    }

  }

}

.title-collapse {
  cursor: pointer;
  font-size: 15px;
  text-align: left;
  padding-left: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;
}

</style>
