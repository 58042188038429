<template>
  <nav class="breadcrumb mt-20" aria-label="breadcrumbs">
    <ul>
      <li><a class="bread title" href="#">{{ title }}</a></li>
      <li v-if="subtitle" class="is-active"><a class="bread subtitle" href="#" aria-current="page">{{ subtitle }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "breadcrumb",
  props: ['title', 'subtitle']
}
</script>

<style scoped lang="scss">
.bread {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.5px;

}

</style>