export const USER_TYPE =
    {
        USER: 0,
        CUSTOMER: 1,
        DEALER: 2,
        AGENT: 3,
        AGENCY: 4,
        MANAGER: 5,
        DELIVERYMAN: 6
    }
