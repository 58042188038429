import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/dashboard/Home.vue'
import IssuersComponent from '../views/issuers/IssuersComponent.vue'
import RecipientsComponent from '../views/recipients/RecipientsComponent.vue'
import DealersComponent from '../views/dealers/DealersComponent.vue'
import store from '@/store/index'
import routesComponent from './routes'
import {clearStorage, exitsKey, saveStorage} from "@/utils";

Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/issuers',
        name: 'issuers',
        component: IssuersComponent
    },
    {
        path: '/recipients',
        name: 'recipients',
        component: RecipientsComponent
    },
    {
        path: '/dealers',
        name: 'dealers',
        component: DealersComponent
    },
]
for (let routesComponentKey in routesComponent) {
    routesComponent[routesComponentKey].forEach(route => {
        routes.push(route)
    })
}

const router = new VueRouter({
    mode: 'history',
    routes
})


const defaultDocumentTitle = 'Remisario'

router.afterEach((to, from) => {
    let token = exitsKey('token')
    let auth = exitsKey('auth')
    if (!token) {
        store.commit('SET_IS_LOGIN', true)
        if (from.path !== '/') {
            store.dispatch('GO_TO', 'login').then(() => {
                clearStorage()
            })
        }
    } else {
        store.commit('SET_IS_LOGIN', false)
        if (auth && to.name !== 'login') {
            store.dispatch('FETCH_PROFILE').then((value) => {
                saveStorage('profile', value.paymentGroups)
            })
        }
        if (to.meta.title) {
            document.title = `${defaultDocumentTitle} - ${to.meta.title}`
        } else {
            document.title = defaultDocumentTitle
        }
    }

})

export default router
