export const COLOR_TOAST = {
  DANGER: 'is-danger',
  WHITE: 'is-white',
  BLACK: 'is-black',
  LIGHT: 'is-light',
  SUCCESS: 'is-success',
  PRIMARY: 'is-primary',
  INFO: 'is-info',
  WARNING: 'is-warning',

}