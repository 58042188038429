<template>
  <div class="modal-card modal-form rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal" v-if="post">{{ $t('dealer_add') }}</div>
          <div class="title-new-modal" v-else>{{ $t('dealer_edit') }}</div>
          <button @click="clearData()" class="delete" aria-label="close"></button>
        </div>
        <section class="pl-20 pr-10 pt-15">
          <div class="card rounded-10">
            <div class="columns mt-30">
              <div class="column is-full">
                <b-field>
                  <b-input v-model="dealer.name" type="text" :placeholder="$t('name')"
                           custom-class="full-input"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns ">
              <div class="column is-half is-flex is-flex-direction-column">
                <b-field>
                  <b-input v-model="dealer.email" type="email" :placeholder="$t('email')"></b-input>
                </b-field>
                <b-field>
                  <b-input v-model="dealer.identificationNumber" type="text" :placeholder="$t('ci')"></b-input>
                </b-field>
                <b-field>
                  <b-switch :value="false"
                            v-model="dealer.isActive"
                            :left-label="true"
                            size="is-medium"
                            type="is-green-success">
                    {{ $t('active') }}
                  </b-switch>
                </b-field>
              </div>
              <div class="column is-half is-flex is-flex-direction-column">
                <b-field>
                  <b-input v-model="dealer.password" type="password" :placeholder="$t('password')"></b-input>
                </b-field>
                <b-field>
                  <b-input v-model="dealer.phoneNumber" type="text" :placeholder="$t('phone')"></b-input>
                </b-field>
                <b-field>
                  <b-switch :value="false"
                            v-model="dealer.handleMLC"
                            :left-label="true"
                            size="is-medium"
                            type="is-green-success">
                    {{ $t('handleMLC') }}
                  </b-switch>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-full">
                <b-field>
                  <b-input v-model="dealer.addressLine1" custom-class="full-input"
                           type="text" :placeholder="$t('address')"></b-input>
                </b-field>
                <b-field>
                  <b-input v-model="dealer.addressLine2" custom-class="full-input"
                           type="text" :placeholder="$t('address_number', { number: 2 })"></b-input>
                </b-field>
                <b-field>
                  <b-input v-model="dealer.addressLine3" custom-class="full-input"
                           type="text" :placeholder="$t('address_number', { number: 3 })"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns" v-if="user.userType !== userTypes.DEALER && user.userType !== userTypes.DELIVERYMAN">
              <div class="column is-half">
                <b-field>
                  <multiselect
                      :placeholder="$t('state')"
                      :custom-label="stateName"
                      v-model="province"
                      open-direction="bottom"
                      :show-no-results="false"
                      :options="stateList">
                  </multiselect>
                </b-field>
              </div>
              <div class="column is-half">
                <b-field>
                  <b-input v-model="dealer.municipality" type="text" :placeholder="$t('city')" custom-class="full-input"></b-input>
<!--                  <multiselect-->
<!--                      :placeholder="$t('city')"-->
<!--                      :custom-label="selectName"-->
<!--                      v-model="municipality"-->
<!--                      open-direction="bottom"-->
<!--                      :show-no-results="false"-->
<!--                      :options="cityList">-->
<!--                  </multiselect>-->
                </b-field>
              </div>
            </div>
            <div class="columns" v-if="user.userType !== userTypes.DEALER && user.userType !== userTypes.DELIVERYMAN">
              <div class="column is-full">
                <b-field>
                  <multiselect
                      track-by="objectId"
                      :placeholder="$t('distribution_zone')"
                      v-model="distributionArea"
                      :custom-label="selectName"
                      :multiple="true"
                      open-direction="bottom"
                      :show-no-results="false"
                      :options="cityList">
                    <template slot="selection" slot-scope="{ values, isOpen }">
                                          <span class="multiselect__single"
                                                v-if="values.length &amp;&amp; !isOpen">{{
                                              values.length
                                            }} {{ $t('areas_selected') }}
                                          </span>
                    </template>
                  </multiselect>
                </b-field>
              </div>
            </div>
          </div>
          <div class="buttons mt-40 is-flex is-align-items-center is-justify-content-flex-end">
            <button @click="save" class="button save is-primary">Guardar</button>
          </div>
        </section>

      </div>
    </section>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapActions, mapGetters} from "vuex";
import {USER_TYPE} from "@/enum/userType";
import {DEALER_TYPE} from "@/enum/dealerType";

export default {
  name: "modalNewDealer",
  components: {Multiselect},
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    dealerId: {
      type: String
    }
  },
  data() {
    return {
      post: true,
      cityList: [],
      stateList: [],
      distributionAreaList: [],
      distributionArea: null,
      country: null,
      province: null,
      municipality: null,
      userTypes: USER_TYPE,
      dealer: {
        type: null,
        email: null,
        password: null,
        identificationNumber: null,
        isActive: true,
        license: null,
        municipality: null,
        province: null,
        name: null,
        last_name: null,
        phoneNumber: null,
        phone2: null,
        country: null,
        zipcode: null,
        addressLine1: null,
        line1StreetNumber: null,
        addressLine1ApartmentNumber: null,
        addressLine2: null,
        addressLine3: null,
        line4Reparto: null,
        distributionArea: null,
        handleMLC: false
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'GET_USER'
    })
  },
  methods: {
    ...mapActions({
      fetchState: 'FETCH_STATES_BY_COUNTRY',
      fetchCountry: 'FETCH_COUNTRY',
      fetchCuba: 'FETCH_CUBA',
      fetchDealers: 'FETCH_DEALER',
      fetchCity: 'FETCH_CITY',
      saveDealer: 'SAVE_DEALER',
      editDealer: 'EDIT_DEALER',
      goBack: 'GO_BACK',
      goTo: 'GO_TO'
    }),
    selectName({name}) {
      return name
    },
    stateName({name}) {
      return name
    },
    save() {
      this.dealer.type = this.user.userType === USER_TYPE.DEALER ? DEALER_TYPE.DELIVERY_MAN : DEALER_TYPE.DEALER
      if (this.user.userType === USER_TYPE.DEALER) {
        this.dealer.province = this.user.province
        this.dealer.municipality = this.user.municipality
        this.dealer.distributionArea = this.user.distributionArea
      } else {
        this.dealer.distributionArea = `${this.province.name},`
        this.distributionArea.forEach(item => {
          this.dealer.distributionArea += `${item.name},`
        })
        this.dealer.distributionArea.slice(0, -1);
      }
      if (this.post) {
        this.saveDealer(this.dealer).then(() => {
          this.clearData()
        })
      } else {
        this.dealer.id = this.dealerId
        this.editDealer(this.dealer).then(() => {
          this.clearData()
        })
      }
    },
    clearData() {
      this.dealer = {}
      this.province = null;
      this.municipality = null;
      this.$emit('close')
      this.$emit('reload')
      this.$emit('refresh')
      window.location.reload()
    },
    getDealers(current, record, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchDealers(data)
            .then(value => {
              resolve(value)
            })
      })

    },
  },
  watch: {
    'province': async function () {
      this.dealer.province = this.province.name
      this.cityList = await this.fetchCity(this.province.id)
      if (this.user.userType !== USER_TYPE.DEALER) {
        // this.cityList = this.cityList.filter(f => f.isAssigned === false)
      }
    },
    'municipality': function () {
      this.dealer.municipality = this.municipality.name
    },

  },
  created() {
    this.fetchCuba().then(async (cuba) => {
      this.country = cuba[0]
      this.stateList = await this.fetchState(this.country.id)
      if (this.edit && this.dealerId) {
        this.post = false
        this.getDealers(1, 1, this.dealerId).then(value => {
          this.dealer.name = value.name
          this.dealer.addressLine1 = value.addressLine
          this.dealer.addressLine2 = value.addressLine
          this.dealer.addressLine3 = value.addressLine
          this.dealer.email = value.email
          this.dealer.identificationNumber = value.identificationNumber
          this.dealer.isActive = value.isActive
          this.dealer.handleMLC = value.handleMLC
          this.dealer.phoneNumber = value.phoneNumber
          this.dealer.country = "Cuba"
          this.province = this.stateList.find(s => s.name === value.province)
          this.distributionArea = []

          this.fetchCity(this.province.id)
              .then(cities => {
                let list = value.distributionArea.split(',')
                for (let i = 1; i < list.length - 1; i++) {
                  this.distributionArea.push(cities.find(c => c.name === list[i]))
                }
                this.dealer.municipality = value.municipality
              })

        })
      }

    })
  }
}
</script>

<style scoped lang="scss">

</style>
