import axios from "axios";
import {api_users} from "@/endpoints";
import {ErrorResource, saveStorage} from "@/utils";

export const userStore = {
    state: () => ({
        profile: null
    }),
    getters: {
        GET_PROFILE(state) {
            return state.profile
        }
    },
    mutations: {
        SET_PROFILE(state, data) {
            saveStorage('profile', data)
            state.profile = data
        }
    },
    actions: {
        FETCH_PROFILE({commit}) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.get(api_users.profile)
                    .then((response) => {
                        commit('SET_PROFILE', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        DELETE_USER({commit}, id) {
            commit('SET_LOADING')
            return new Promise(resolve => {
                axios.delete(`${api_users.users}/${id}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        resolve([])
                        ErrorResource(error)
                    }).finally(() => {
                    commit('SET_LOADING', false)
                })
            })
        },
        UPLOAD_FILE({commit}, pic) {
            commit('SET_LOADING')
            let form = new FormData()
            form.append('file', pic.file)
            return new Promise(resolve => {
                axios.post(api_users.upload_picture_files, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(reason => {
                        ErrorResource(reason)
                    })
                    .finally(() => {
                        commit('SET_LOADING', false)
                    })
            })
        }
    }
}