<template>
  <div class="paginate is-flex is-justify-content-space-between">
    <div class="is-flex is-align-items-center">
      <label class="mx-10 text-paginate">{{ $t('per_page') }}</label>
      <b-field>
        <b-select v-model="per_page_value">
          <option :value="5">5</option>
          <option :value="10">10</option>
          <option :value="15">15</option>
          <option :value="count">{{ $t('all') }}</option>
        </b-select>
      </b-field>
    </div>
    <div class="is-flex is-align-items-center">
      <div>
        <label class="mx-10 text-paginate">{{ $t('page') }}</label>
      </div>

      <div>
        <b-field>
          <b-input v-model="currentPageValue"
                   custom-class="input-paginate"
                   type="text"
          >
          </b-input>
        </b-field>
      </div>
      <div class="is-flex">
        <label class="mx-10 text-paginate">{{ $t('of') }}<span
            class="mx-10 text-paginate">{{ totalPages }}</span></label>
        <div v-if="currentPageValue > 1" @click="$emit('first')">
          <b-icon class="cursor-pointer" icon="skip-previous" type="is-green-success"></b-icon>
        </div>
        <div v-if="currentPageValue > 1" @click="$emit('previous')">
          <b-icon class="cursor-pointer" custom-class="mdi-rotate-180" icon="play" type="is-green-success"></b-icon>
        </div>

        <div v-if="currentPageValue<totalPages" class="ml-5" @click="$emit('next')">
          <b-icon class="cursor-pointer" icon="play" type="is-green-success"></b-icon>
        </div>
        <div v-if="currentPageValue<totalPages" @click="$emit('last')">
          <b-icon class="cursor-pointer" icon="skip-next" type="is-green-success"></b-icon>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "paginate",
  data() {
    return {
      per_page_value: null,
      currentPageValue: null
    }
  },

  props: {
    per_page: {
      type: Number
    },
    count: {
      type: Number
    },
    currentPage: {
      type: Number
    },
    totalPages: {
      type: Number
    },

  },
  watch: {
    currentPage: function () {
      this.per_page_value = this.per_page
      this.currentPageValue = this.currentPage
    },
    currentPageValue: function () {
      if (this.currentPageValue && this.currentPageValue <= this.totalPages) {
        this.$emit('search', this.currentPageValue)
      }
    },
    per_page_value: function () {
      if (this.per_page_value) {
        switch (this.per_page_value) {
          case 5:
            this.$emit('perPage', 5)
            break;
          case 10:
            this.$emit('perPage', 10)
            break;
          case 15:
            this.$emit('perPage', 15)
            break;
          case this.count:
            this.$emit('perPage', this.count)
        }
      }

    }
  },
}
</script>

<style scoped lang="scss">
.text-paginate {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #AEAEAE;
}
</style>