const ClosuresComponent = () => import('../views/accounting/ClosuresComponent.vue')
const ClosuresDealerComponent = () => import('../views/accounting/ClosuresDealerComponent.vue')
const DailyCreditComponent = () => import('../views/accounting/DailyCreditComponent.vue')
const BalancesComponent = () => import('../views/accounting/BalancesComponent.vue')
const RealBalancesComponent = () => import('../views/accounting/RealBalancesComponent.vue')
const OperationsComponents = () => import('../views/accounting/OperationsComponents.vue')
const SalesComponent = () => import('../views/accounting/SalesComponent.vue')
const SalesDealerComponent = () => import('../views/accounting/SalesDealerComponent.vue')
let path = '/accounting/'

export const AccountingRoutes = [
    {
        path: `${path}closures/`,
        name: 'closures',
        component: ClosuresComponent
    },
    {
        path: `${path}dealer/closures/`,
        name: 'closuresDealer',
        component: ClosuresDealerComponent
    },
    {
        path: `${path}daily/credit/`,
        name: 'dailyCredit',
        component: DailyCreditComponent
    },
    {
        path: `${path}balances/`,
        name: 'balances',
        component: BalancesComponent
    },
    {
        path: `${path}balances/real/`,
        name: 'realBalances',
        component: RealBalancesComponent
    },
    {
        path: `${path}operations/`,
        name: 'operations',
        component: OperationsComponents
    },
    {
        path: `${path}sales/`,
        name: 'sales',
        component: SalesComponent
    },
    {
        path: `${path}dealer/sales/`,
        name: 'salesDealer',
        component: SalesDealerComponent
    }
]