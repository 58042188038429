<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <div class="is-flex is-align-items-center">
          <img class="brand-nav" v-if="user.userType === userTypes.AGENCY && user.profilePicture"
               :src="user.profilePicture" alt="">
        </div>
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <div class="is-flex is-align-items-center">
          <div v-if="user.userType !== userTypes.MANAGER"
               class="is-flex is-align-items-center cursor-pointer">
            <b-dropdown :triggers="['hover']" aria-role="list">
              <template #trigger>
                <img class="img-dolar" :src="dolar" alt=""> <span class="mx-10 dolar">{{ currency }}</span>
              </template>
              <b-dropdown-item v-for="(item, idx) in currencyList" :key="idx"
                               @click="changeInfo(item)"
                  aria-role="listitem">{{item}}</b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-if="user.userType !== userTypes.MANAGER">
            <b-taglist>
              <b-tooltip class="mx-4" type="is-pink-light" :label="$t('balance')">
                <b-tag type="is-pink-light" rounded>${{ amount }}</b-tag>
              </b-tooltip>
              <b-tooltip class="mx-4" type="is-green-accent" :label="$t('expenses')">
                <b-tag type="is-green-accent" rounded>${{ expenses }}</b-tag>
              </b-tooltip>
              <b-tooltip v-if="user.userType !== userTypes.DEALER && user.userType !== userTypes.DELIVERYMAN"
                         class="mx-4" type="is-green-success"
                         :label="$t('gain')">
                <b-tag type="is-green-success" rounded>{{ remainingPercent }}%</b-tag>
              </b-tooltip>
            </b-taglist>
          </div>
          <div class="cursor-pointer ml-10" @click="changeLang('en')" v-if="lang==='es'">
            <svg width="30" height="30">
              <image :xlink:href="es" width="30" height="30"/>
            </svg>
          </div>
          <div class="cursor-pointer ml-10" @click="changeLang('es')" v-if="lang==='en'">
            <svg width="30" height="30">
              <image :xlink:href="gb" width="30" height="30"/>
            </svg>
          </div>
          <div class="ml-20 is-flex is-align-items-center notification-tag">
            <b-tooltip :label="$t('see_next')">
              <b-icon size="is-large" icon="bell-outline"></b-icon>
            </b-tooltip>
            <b-tag type="is-pink-light" rounded>0</b-tag>
            <account :show_utilities="show_utilities"/>
          </div>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import cubamax from '../../assets/img/cubamax.png'
import es from '../../assets/img/es.svg'
import gb from '../../assets/img/gb.svg'
import dolar from '../../assets/img/dolar.png'
import Account from "@/components/structure/account";
import {USER_TYPE} from "@/enum/userType";
import {getStorage, saveStorage} from "@/utils";
import {AGENT_TYPE} from "@/enum/agentType";

export default {
  name: "nav-bar",
  components: {Account},
  data() {
    return {
      es, gb,
      show_utilities: false,
      remainingPercent: null,
      expenses: null,
      amount: null,
      currency: null,
      currencyList: [],
      search1: '',
      userTypes: USER_TYPE,
      cubamax,
      dolar,
    }
  },
  props: {
    pageTitle: {
      type: String
    },
    search: {
      type: String
    },
    is_search: {
      type: Boolean,
      default: true
    },
    is_back: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'SET_LOADING',
      changeLanguage: 'SET_LANG'
    }),
    ...mapActions({
      goBack: 'GO_BACK',
      fetchProfile: 'FETCH_PROFILE'

    }),
    changeLang(newLang) {
      this.changeLanguage(newLang)
      window.location.reload()
    },
    setInfo() {
      let paymentGroups = getStorage('profile')
      this.currencyList = paymentGroups.map(p => (p.currencyTo))
      let pg = paymentGroups[0]
      this.currency = pg.currencyTo
      this.expenses = pg.expenses
      if (this.user.userType !== USER_TYPE.DEALER) {
        this.remainingPercent = pg.managerCommission.rate
      }
      this.amount = pg.amount
    },
    changeInfo(currency) {
      let paymentGroups = getStorage('profile')
      let pg = paymentGroups.find(c => c.currencyTo === currency)
      if (pg) {
        this.currency = pg.currencyTo
        this.expenses = pg.expenses
        if (this.user.userType !== USER_TYPE.DEALER) {
          this.remainingPercent = pg.managerCommission.rate
        }
        this.amount = pg.amount
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'GET_USER',
      auth: 'GET_AUTH',
      profile: 'GET_PROFILE',
      isLoading: 'GET_LOADING',
      lang: 'GET_LANG'

    })
  },
  watch: {
    isLoading: function () {
      if (this.user.userType !== USER_TYPE.MANAGER && this.auth) {
        this.changeInfo()
        this.show_utilities = this.user.agentType === AGENT_TYPE.EXTERNAL || this.user.userType === USER_TYPE.AGENCY;
      }
    },
  },
  mounted() {
    this.fetchProfile().then((value) => {
      saveStorage('profile', value.paymentGroups)
      if (this.user.userType !== USER_TYPE.MANAGER && this.auth) {
        this.setInfo()
      }
    })

  },
  created() {
    this.setLoading(true)
    if (this.auth) {
      this.setLoading(false)
      this.fetchProfile().then((value) => {
        saveStorage('profile', value.paymentGroups)
      })
      this.show_utilities = this.user.agentType === AGENT_TYPE.EXTERNAL || this.user.userType === USER_TYPE.AGENCY;
    }
    if (this.user.userType !== USER_TYPE.MANAGER && this.auth) {
      this.setInfo()
    }
  }
}
</script>

<style scoped lang="scss">
.navbar {
  height: 132px;
  padding: 40px;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  box-shadow: 20px 20px 44px -35px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}

.brand-nav {
  height: 55px;
  width: 164px;
}

.tags {
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
}

.notification-tag {
  .tag {
    margin-left: -40px;
    margin-top: -25px;
    position: relative;
  }
}

.dolar {
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 1366px) {
  .navbar {
    height: 70px;
    padding: 10px;
  }

  .brand-nav {
    height: 40px;
    width: 164px;
  }

  .tags {
    font-weight: bold;
    font-size: 12px;
  }

  .notification-tag {
    .tag {
      margin-left: -40px;
      margin-top: -25px;
    }
  }
  .img-dolar {
    height: 16px;
  }
  .dolar {
    font-weight: bold;
    font-size: 14px;
  }
}
</style>
