const AgencyBalanceComponent = () => import('../views/balances/AgencyBalanceComponent.vue')
const AgentBalanceComponent = () => import('../views/balances/AgentBalanceComponent.vue')
const DealerBalanceComponent = () => import('../views/balances/DealerBalanceComponent.vue')
const LogBalancesDealer = () => import('../views/balances/LogBalancesDealer.vue')
let path = '/balances/'
export const BalancesRoutes = [
    {
        path: `${path}agency/`,
        name: 'balanceAgency',
        component: AgencyBalanceComponent
    },
    {
        path: `${path}agent/`,
        name: 'balanceAgent',
        component: AgentBalanceComponent
    },
    {
        path: `${path}dealer/`,
        name: 'balanceDealer',
        component: DealerBalanceComponent
    },
    {
        path: `${path}dealer/log/`,
        name: 'logBalancesDealer',
        component: LogBalancesDealer
    }
]