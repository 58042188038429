<template>
  <div class="home">
    <div class="columns pa-0">
      <div class="column is-half pa-0">
        <div class="is-flex is-flex-direction-column">
          <span class="dash-title">Dashboard</span>
          <span class="dash-sub-title">Toda la información que necesitas de tu empresa.</span>
          <div class="greeting is-flex is-flex-direction-column is-justify-content-center pl-35">
            <div class="title">{{ $t('hi', {name: user.name}) }}!</div>
            <div class="sub-title">Bienvenido, estas son las novedades actualizadas.</div>
          </div>
        </div>
      </div>
      <div class="column is-half pa-0 mt-17">
        <img class="dash-img" :src="dashImg" alt="">
      </div>
    </div>
    <div class="columns" v-if="user.userType !== userTypes.DEALER && user.userType !== userTypes.DELIVERYMAN">
      <div class="column is-flex is-flex-direction-column"
           :class="{'is-full': user.userType === userTypes.AGENT, 'is-half':user.userType !== userTypes.AGENT}">
        <div class="card rounded-10 card-statics-1">
          <div v-if="user.userType !== userTypes.AGENT" class="columns is-flex is-justify-content-space-between">
            <div class="column is-flex is-flex-direction-column">
              <div>
                <b-dropdown
                    v-model="frequency"
                    append-to-body
                    aria-role="menu"
                    scrollable
                    max-height="200"
                    trap-focus
                >
                  <template v-if="frequency === 0" #trigger>
                    <a
                        class="frequency"
                        role="button">
                      <span>Diario</span>
                      <b-icon icon="menu-down"></b-icon>
                    </a>
                  </template>

                  <template v-else-if="frequency===1" #trigger>
                    <a
                        class="frequency"
                        role="button">
                      <span>Semanal</span>
                      <b-icon icon="menu-down"></b-icon>
                    </a>
                  </template>
                  <template v-else-if="frequency === 2" #trigger>
                    <a
                        class="frequency"
                        role="button">
                      <span>Mensual</span>
                      <b-icon icon="menu-down"></b-icon>
                    </a>
                  </template>
                  <template v-else-if="frequency === 3" #trigger>
                    <a
                        class="frequency"
                        role="button">
                      <span>Anual</span>
                      <b-icon icon="menu-down"></b-icon>
                    </a>
                  </template>
                  <b-dropdown-item :value="0" aria-role="listitem">
                    <span>Diario</span>
                  </b-dropdown-item>

                  <b-dropdown-item :value="1" aria-role="listitem">
                    <span>Semanal</span>
                  </b-dropdown-item>
                  <b-dropdown-item :value="2" aria-role="listitem">
                    <span>Mensual</span>
                  </b-dropdown-item>
                  <b-dropdown-item :value="3" aria-role="listitem">
                    <span>Anual</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="amount" v-if="totalReportItem">
                ${{ totalReportItem.amount }} <span class="amount-currency">{{ totalReportItem.currency }}</span>
              </div>
              <div class="amount" v-else>
                $0.00
              </div>
              <div class="count-remittance" v-if="totalReportItem">
                {{ totalReportItem.totalRemittances }} remesas
              </div>
              <div class="count-remittance" v-else>
                0 remesas
              </div>
            </div>
            <div class="column is-flex is-justify-content-flex-end">
              <div class="is-flex is-flex-direction-column is-justify-content-space-between">
                <div class="is-flex">
                  <div class="mr-60">
                    <b-field>
                      <b-datepicker
                          ref="dateRange"
                          v-model="datesTotalRemittances"
                          range>
                        <template v-slot:trigger>
                          <b-dropdown
                              v-model="range"
                              append-to-body
                              aria-role="menu"
                              scrollable
                              max-height="200"
                              trap-focus
                          >
                            <template v-if="range === 1" #trigger>
                              <a
                                  class="range"
                                  role="button">
                                <span>{{ currentDateRemittances }}</span>
                                <b-icon icon="menu-down"></b-icon>
                              </a>
                            </template>
                          </b-dropdown>
                        </template>
                      </b-datepicker>
                    </b-field>
                  </div>
                  <b-dropdown :triggers="['hover']" aria-role="list">
                    <template #trigger>
                      <div class="is-flex is-align-items-center">
                        <img class="img-dolar cursor-pointer" :src="dolar" alt=""><span class="mx-10 dolar">{{ currency }}</span>
                      </div>
                    </template>
                    <b-dropdown-item v-for="(item, idx) in currencyList" :key="idx"
                                     @click="getTotalsCurrency(item)"
                                     aria-role="listitem">{{item}}</b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="is-flex is-justify-content-flex-end percentage">
                  <div class="mr-10">
                    + 0,7%
                  </div>
                  <div>
                    <span class="mdi mdi-arrow-up"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 card rounded-10">
          <div class="columns is-flex is-justify-content-space-between card-statics-3">
            <div>Balance</div>
            <div>
              <b-field>
                <b-datepicker
                    ref="dateRange"
                    v-model="datesChart"
                    range>
                  <template v-slot:trigger>
                    <b-dropdown
                        v-model="range"
                        append-to-body
                        aria-role="menu"
                        scrollable
                        max-height="200"
                        trap-focus
                    >
                      <template v-if="range === 1" #trigger>
                        <a
                            class="range"
                            role="button">
                          <span>{{ currentDateRange }}</span>
                          <b-icon icon="menu-down"></b-icon>
                        </a>
                      </template>
                    </b-dropdown>
                  </template>
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full" v-if="series[0].data.length && chartOptions.xaxis.categories.length">
              <apexchart type="line" height="350" width="100%" :options="chartOptions" :series="series"/>
            </div>
          </div>
        </div>
      </div>
      <div v-if="user.userType !== userTypes.AGENT" class="column card rounded-10 card-statics-2 is-half">
        <div class="columns">
          <div class="column is-flex is-justify-content-space-between">
            <div class="title-panel">
              <span v-if="user.userType === userTypes.AGENCY">Remesas por agentes</span>
              <span v-else>Remesas por Agencias</span>
            </div>
            <div class="frequency-remittance is-flex">
              <b-field>
                <div class="cursor-pointer" @click="selected =0">
                  <b-tag v-if="selected!==0"><span class="tag-panel">Diario</span></b-tag>
                  <b-tag type="is-green-accent" v-if="selected===0"><span class="tag-panel selected">Diario</span>
                  </b-tag>
                </div>
              </b-field>
              <b-field>
                <div class="cursor-pointer" @click="selected =1">
                  <b-tag v-if="selected!==1"><span class="tag-panel">Semanal</span></b-tag>
                  <b-tag type="is-green-accent" v-if="selected===1"><span class="tag-panel selected">Mensual</span>
                  </b-tag>

                </div>
              </b-field>
              <b-field>
                <div class="cursor-pointer" @click="selected =2">
                  <b-tag v-if="selected!==2"><span class="tag-panel">Mensual</span></b-tag>
                  <b-tag type="is-green-accent" v-if="selected===2"><span class="tag-panel selected">Mensual</span>
                  </b-tag>
                </div>
              </b-field>
            </div>
            <b-dropdown :triggers="['hover']" aria-role="list">
              <template #trigger>
                <div class="is-flex is-align-items-center">
                  <img class="img-dolar mr-15 cursor-pointer" :src="dolar" alt="">
                  <span v-if="currentCurrency" class="mx-10 dolar">{{ currentCurrency }}</span>
                </div>
              </template>
              <b-dropdown-item v-for="(item, idx) in currencyList" :key="idx"
                               @click="getRemittancesTable(item)"
                               aria-role="listitem">{{item}}</b-dropdown-item>
            </b-dropdown>

          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-table
                :data="desserts"
                ref="table"
                :paginated="false">
              <b-table-column field="name" :label="$t('name')" v-slot="props">
                <div class="is-flex is-align-items-center">
                  <avatar class="avatar-size" color="#FFFFFF" :backgroundColor="getAvatarColor(props.index)" :size="40"
                          :username="props.row.name"
                          :src="props.row.pictureUrl"/>
                  <span class="ml-3 col name">{{ props.row.name }}</span>
                </div>
              </b-table-column>

              <b-table-column field="symbol" sortable>
                <template v-slot:header>
                  <div class="is-flex is-justify-content-space-between">
                    <span>{{ $t('delivery') }}</span>
                    <span style="text-align: end">{{ $t('available') }}</span>
                  </div>
                </template>
                <template v-slot="props">
                  <div class="is-flex is-flex-direction-column">
                    <div class="is-flex is-justify-content-space-between">
                      <span class="col init-value">${{ props.row.amount }}</span>
                      <span class="col end-value">${{ props.row.balance }}</span>
                    </div>
                    <div>
                      <b-progress size="is-small" :type="getType(props.index)" :value="getProgress(props.row)"
                                  :show-value="false"></b-progress>
                    </div>
                  </div>
                </template>
              </b-table-column>

              <b-table-column field="exchange_rate" :label="$t('quantity')" sortable v-slot="props">
                  <span class="col" :class="getClassColor(props.index)">
                     {{ props.row.totalRemittance }}
                  </span>
              </b-table-column>

            </b-table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import dashImg from '@/assets/img/dash-img.png'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import dolar from "@/assets/img/dolar_dash.png";
import Avatar from 'vue-avatar'
import {USER_TYPE} from "@/enum/userType";
import {CURRENCY} from "@/enum/currency";

export default {
  name: 'Home',
  components: {Avatar},

  data() {
    return {
      currencyList: Object.values(CURRENCY),
      dates: [],
      totalReport: [],
      totalReportItem: null,
      datesChart: [],
      datesTotalRemittances: [],
      currentDateRange: null,
      currentDateRemittances: null,
      dashImg, dolar,
      initDate: null,
      finalDate: null,
      rangeSelector: null,
      agencyId: null,
      currency: null,
      currencyTable: null,
      currentCurrency: null,
      frequency: 0,
      range: 1,
      selected: 0,
      userTypes: USER_TYPE,
      desserts: [],
      series: [{
        name: "Cantidad Remesas",
        data: []
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },

        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          // type: 'datetime',
          categories: [],
        }
      },

    }
  },
  computed: {
    ...mapGetters({
      user: 'GET_USER'
    }),
  },
  watch: {
    selected: function () {
      this.getRemittancesTable(false)
    },
    datesChart: function () {
      if (this.datesChart.length > 1) {
        this.currentDateRange = `${this.formatYYYYMMDD(this.datesChart[0])} - ${this.formatYYYYMMDD(this.datesChart[1])}`
      }
      this.getRemittancesStatics()
    },
    datesTotalRemittances: function () {
      if (this.datesTotalRemittances.length > 1) {
        this.currentDateRemittances = `${this.formatYYYYMMDD(this.datesTotalRemittances[0])} - ${this.formatYYYYMMDD(this.datesTotalRemittances[1])}`
      }
      this.getTotals()
    },
    frequency: function () {
      this.getTotals()
    }
  },
  methods: {
    ...mapMutations({
      setDash: 'SET_IS_DASH',
      setLoginPage: 'SET_IS_LOGIN'
    }),
    ...mapActions({
      fetchTotalRemittances: 'FETCH_TOTAL_REMITTANCES_DASH',
      fetchRemittanceStatics: 'FETCH_REMITTANCES_STATICS',
      fetchAgentRemittance: 'FETCH_AGENTS_REMITTANCES'
    }),
    currentWeek() {
      let curr = new Date; // get current date
      let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      let last = first + 6; // last day is the first day + 6
      let firstday = new Date(curr.setDate(first)).toUTCString();
      let lastday = new Date(curr.setDate(last)).toUTCString();
      return `${this.formatYYYYMMDD(firstday)} - ${this.formatYYYYMMDD(lastday)}`
    },
    getType(index) {
      if (index % 2 === 0) {
        return 'is-pink-light'
      } else {
        return 'is-green-accent'
      }
    },
    getClassColor(index) {
      if (index % 2 === 0) {
        return 'pink-light'
      } else {
        return 'green-accent'
      }
    },
    getAvatarColor(index) {

      
      if (index % 2 === 0) {

        
        return '#FDC4D1'

        
      } else {

        
        return '#7DDBE5'

        
      }
    },
    getProgress(item) {
      return item.balance > 0 ? (item.amount * 100) / item.balance : 100
    },
    formatYYYYMMDD(value) {
      const date = new Date(value)
      if (value && !isNaN(date)) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return year + '-' +
            ((month < 10 ? '0' : '') + month) + '-' +
            ((day < 10 ? '0' : '') + day)
      }
      return ''
    },
    getTotalsCurrency(currency) {
      this.currency = currency
      console.log(this.totalReport);
      const report = this.totalReport.find(f => f.currency === currency)
        if (report) {
          this.currency = report.currency
          this.totalReportItem = report
        }
    },
    getRemittancesStatics() {
      let query = ''
      if (this.user.userType === USER_TYPE.AGENCY) {
        query = `?agencyId=${this.user.id}`
      }
      if (this.datesChart.length > 1) {
        let prefix = query.length ? '&' : '?'
        query += `${prefix}initDate=${this.formatYYYYMMDD(this.datesChart[0])}&finalDate=${this.formatYYYYMMDD(this.datesChart[1])}`
      }
      this.fetchRemittanceStatics(query)
          .then(value => {
            value.forEach(item => {
              this.series[0].data.push(item.total)
              this.chartOptions.xaxis.categories.push(this.formatYYYYMMDD(item.date))
            })
          })
    },
    getRemittancesTable(currency) {
      this.currentCurrency = currency;
      let query = ``
      if (this.user.userType === USER_TYPE.AGENCY) {
        query = currency ? `?agencyId=${this.user.id}&currency=${currency}` : `?agencyId=${this.user.id}`
      } else {
        query = currency ? `?currency=${currency}` : ``
      }
      let prefix = query.length ? '&' : '?'
      query += `${prefix}rangeSelector=${this.selected}`
      this.fetchAgentRemittance(query)
          .then(value => {
            this.desserts = value
          })
    },
    getTotals(currency) {
      let query = ''
      let prefix = ''
      if (this.user.userType === USER_TYPE.AGENCY) {
        query = currency ? `?agencyId=${this.user.id}&currency=${currency}` : `?agencyId=${this.user.id}`
      }
      if (this.datesTotalRemittances.length > 1) {
        prefix = query.length ? '&' : '?'
        query += `${prefix}initDate=${this.formatYYYYMMDD(this.datesTotalRemittances[0])}&finalDate=${this.formatYYYYMMDD(this.datesTotalRemittances[1])}`
      }
      if (this.frequency !== null) {
        prefix = query.length ? '&' : '?'
        query += `${prefix}rangeSelector=${this.frequency}`
      }
      this.fetchTotalRemittances(query)
          .then(value => {
            this.totalReport = value.report
            if (value.report.length) {
              this.totalReportItem = value.report[0]
            } else {
              this.totalReportItem = null
            }
          })
    }
  },

  created() {
    this.currentDateRange = this.currentWeek()
    this.currentDateRemittances = this.currentWeek()
    this.setDash()
    this.setLoginPage(false)
    this.getTotals()
    this.getRemittancesStatics()
    if (this.user.userType !== USER_TYPE.AGENT) {
      this.getRemittancesTable(false)
    }

  },
  destroyed() {
    this.setDash(false)
  }
}
</script>
<style scoped lang="scss">
@import "../../../theme/variables";

.selected {
  color: #FFFFFF !important;
}

.pink-light {
  color: $pink-light;
}

.green-accent {
  color: $green-accent;
}

.tag-panel {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #FDC4D1;
}

.title-panel {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #0B2B46;

}

.col {
  font-style: normal;
  line-height: 19px;
  font-size: 14px;

  &.name {
    font-weight: normal;
    color: #404040;
  }

  &.init-value {
    font-weight: bold;
    color: #7DDBE5;
  }

  &.end-value {
    color: #C1C1C1;
  }

}

.card-statics-1, .card-statics-2, .card-statics-3 {
  padding: 23px 33px 14px 24px;
}

.percentage {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #7DDBE5;

}

.count-remittance {
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #C4C4C4;

}

.amount {
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 48px;
  display: flex;
  align-items: center;
  color: #0B2B46;

  .amount-currency {
    font-size: 20px;
    position: relative;
    top: 6px;
    margin-left: 5px;
  }
}

.frequency {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #0B2B46;

}

.range {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #4F4F4F;
}

.dash-title {
  margin-top: 40px;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: $dark;
}

.dash-sub-title {
  margin-top: 12px;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.007em;
  color: #C4C4C4;

}

.greeting {
  margin-top: 30px;
  background: linear-gradient(90deg, #FDC4D1 43.23%, rgba(253, 196, 209, 0.75) 100%);
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 25px;
  height: 209px;
  width: 1435px;
  font-weight: 600;
  letter-spacing: 0.5px;


  .title {
    font-size: 60px;
    line-height: 32px;
    color: #FFFFFF;
  }

  .sub-title {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.007em;
    color: #FFFFFF;
  }

}

@media screen and (max-width: 1366px) {
  .dash-img {
    height: 190px;
  }
  .dash-title {
    margin-top: 20px;
    font-size: 18px;
  }

  .dash-sub-title {
    margin-top: 8px;
    font-size: 14px;

  }

  .greeting {
    margin-top: 20px;
    height: 100px;
    width: 960px;

    .title {
      font-size: 30px;
      line-height: 0;
    }

    .sub-title {
      font-size: 14px;
      line-height: 0;
    }

  }
}

.mdi-playlist-plus {
  color: #C4C4C4;
}
</style>
