const ListInvoiceComponent = () => import('../views/invoice/ListInvoiceComponent.vue')
const TransfersComponent = () => import('../views/invoice/TransfersComponent.vue')

export const InvoicesRoutes = [
    {
        path: '/invoice/list/',
        name: 'invoiceList',
        component: ListInvoiceComponent
    },
    {
        path: '/invoice/transfers/',
        name: 'transfers',
        component: TransfersComponent
    }
]