const URL_API = process.env.VUE_APP_BASE_URL;
const PATH = process.env.VUE_APP_BASE_PATH;
const PLACES = process.env.VUE_APP_PLACES_URL;
export const api_url = `${URL_API}/${PATH}`

export const api_agency = `${api_url}/agency`
export const api_agency_balance_assignments = `${api_url}/agency/balance_assignments`
export const api_agency_exchange_rate = `${api_url}/agency/exchange_rate/{id}`

export const api_agent_balance_assignments = `${api_url}/agent/balance_assignments`
export const api_agent_exchange_rate = `${api_url}/agent/exchange_rate/{id}`
export const api_agent = `${api_url}/agent`
export const auth = `${api_url}/auth0`
export const customer = `${api_url}/customer`
export const dashboard = `${api_url}/dashboard`
export const api_dealer = `${api_url}/dealer`
export const api_dealer_balances = `${api_url}/dealer/balance_assignments`
export const api_dealer_transfer = `${api_url}/dealer/transfer`
export const api_dealer_daily_credit_report = `${api_url}/dealer/dayli_credit_report`
export const api_dealer_expenses = `${api_url}/dealer/expenses/{id}`
export const api_delivery_man = `${api_url}/delivery_men`
export const files = `${api_url}/files`
export const manager = `${api_url}/files`
export const nomenclators = `${api_url}/nomenclators`
export const remittances = `${api_url}/remittances`
export const sources = `${api_url}/sources`
export const transfers = `${api_url}/transfers`
export const users = `${api_url}/users`

export const api_auth = {
    token: `${auth}/token`,
    auth0: `${auth}/email/{email}`,
    login: `${auth}/login`,
    test: `${auth}/test`
}

export const api_sources = {
    manager: `${sources}/deposit/manager`,
    user: `${sources}/deposit/{id}`,
    delivery: `${sources}/deposit/delivery/{id}`,
    agency: `${sources}/deposit/agency/{id}`,
    agent: `${sources}/deposit/agent/{id}`,
    dealer: `${sources}/deposit/dealer/{id}`,
    debits: `${sources}/debits`,
    pay_debits: `${sources}/pay_debits`
}

export const api_remittances = {
    remittance: `${remittances}/`,
    details: `${remittances}/details/{id}`,
    dealer: `${remittances}/dealer`,
    accept: `${remittances}/{id}/accept`,
    assign: `${remittances}/{id}/assign/dealer`,
    fees: `${remittances}/fees`,
    error: `${remittances}/{id}/error`,
    cancel: `${remittances}/{id}/cancel`,
    complete: `${remittances}/{id}/complete`,
    payment_group: `${remittances}/{id}/payment_group`

}

export const api_customer = {
    add_card: `${customer}/add_card`,
    get_cards_by_user: `${customer}/{id}/cards`,
    customer: `${customer}/`,
    details: `${customer}/{id}/details`,
    get_sender: `${customer}/sender`,
    get_receiver: `${customer}/reciever`
}

export const api_users = {
    users,
    upload_picture: `${users}/profile_picture`,
    upload_picture_files: `${api_url}/files/uploadfile`,
    profile: `${users}/profile`,
    change_password: `${users}/change_password/{email}`,
    forgot_password: `${users}/forgot_password/{email}`,
    reset_password: `${users}/reset_password/{email}`,
}

export const api_dashboard = {
    dealer_statistics: `${dashboard}/dealer/statistics`,
    remittance_statistics: `${dashboard}/remittance/statistics`,
    agency_report: `${dashboard}/agency/report`,
    agent_report: `${dashboard}/agent/report`,
    agent_remittance: `${dashboard}/agent/remittance`,
    sales: `${dashboard}/sales`,
    dealer_sales: `${dashboard}/dealer/sales`,
    dealer_closing: `${dashboard}/dealer/closing`,
    agency_remittance_total: `${dashboard}/agency/remittance/total`,

}

export const api_nomenclators = {
    cuba: `${PLACES}/countries/search?iso3=CUB`,
    currency: `${nomenclators}/currency`,
    state: `${nomenclators}/statescountry/country/{code}`,
    country: `${PLACES}/countries/search`,
    state_country: `${PLACES}/states/{country}/search`,
    city: `${PLACES}/cities/{state}/search`,
    city_state: '',
    cuban_cities: `${nomenclators}/cuban_cities/`,
    cuban_states: `${nomenclators}/cuban_states/`,
    municipality: `${nomenclators}/municipality/`,
    province: `${nomenclators}/province/`,
    payment_group: `${nomenclators}/payment_group/`,
}
