const ListUserComponent = () => import('../views/users/listUserComponent.vue')
const NewUserComponent = () => import('../views/users/newUserComponent.vue')
const LoginComponent = () => import('../views/users/loginComponent.vue')
const ChangePasswordComponent = () => import('../views/users/changePasswordComponent.vue')
const ForgotPasswordComponent = () => import('../views/users/forgotPasswordComponent.vue')
const ResetPasswordComponent = () => import('../views/users/resetPasswordComponent.vue')
let path = '/users/'

export const usersRoutes = [
    {
        path: '/',
        name: 'login',
        component: LoginComponent
    },
    {
        path: `${path}password/change/`,
        name: 'changePassword',
        component: ChangePasswordComponent
    },
    {
        path: `${path}password/forgot/`,
        name: 'forgotPassword',
        component: ForgotPasswordComponent
    },
    {
        path: `${path}password/reset/`,
        name: 'resetPassword',
        component: ResetPasswordComponent
    },
    {
        path: `${path}list/`,
        name: 'listUser',
        component: ListUserComponent
    },
    {
        path: `${path}form/`,
        name: 'newUser',
        component: NewUserComponent
    }
]