const AddresseeComponent = () => import('../views/reports/AddresseeComponent.vue')
const AgencyComponent = () => import('../views/reports/AgencyComponent.vue')
const AgentComponent = () => import('../views/reports/AgentComponent.vue')
const AttorneyComponent = () => import('../views/reports/AttorneyComponent.vue')
const DistributionAgencyComponent = () => import('../views/reports/DistributionAgencyComponent.vue')
const DistributorComponent = () => import('../views/reports/DistributorComponent.vue')
const DistributorShippingComponent = () => import('../views/reports/DistributorShippingComponent.vue')
const PendingDistributorComponent = () => import('../views/reports/PendingDistributorComponent.vue')
let path = '/reports/'

export const reportsRoutes = [
    {
        path: `${path}addressee/`,
        name: 'addresseeReport',
        component: AddresseeComponent
    },
    {
        path: `${path}agency/`,
        name: 'agencyReport',
        component: AgencyComponent
    },
    {
        path: `${path}history/`,
        name: 'agentReport',
        component: AgentComponent
    },
    {
        path: `${path}attorney/`,
        name: 'attorneyReport',
        component: AttorneyComponent
    },
    {
        path: `${path}distributionAgency/`,
        name: 'distributionAgency',
        component: DistributionAgencyComponent
    },
    {
        path: `${path}distributor/`,
        name: 'distributor',
        component: DistributorComponent
    },
    {
        path: `${path}distributorShipping/`,
        name: 'distributorShipping',
        component: DistributorShippingComponent
    },
    {
        path: `${path}pendingDistributor/`,
        name: 'pendingDistributor',
        component: PendingDistributorComponent
    }
]