<template>
  <div class="modal-card modal-form rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">Nuevo Destinatario</div>
          <button @click="events" class="delete" aria-label="close"></button>
        </div>
        <section>
          <div class="card rounded-10">
            <b-tabs type="is-boxed">
              <b-tab-item>
                <template #header>
                  <span class="tag is-green-success mx-10 rounded-10">Obligatorios</span>
                </template>
                <b-tabs type="is-boxed">
                  <b-tab-item>
                    <template #header>
                      <span class="tag is-transfer mx-10 rounded-10">Trasferencia</span>
                    </template>
                    <div class="columns">
                      <div class="column is-half">
                        <b-field>
                          <multiselect
                              :placeholder="`${$t('issuer')}`"
                              v-model="sender"
                              :custom-label="selectNameIssuer"
                              :searchable="true"
                              open-direction="bottom"
                              :show-no-results="false"
                              :options="senderList">
                          </multiselect>
                        </b-field>
                        <b-field>
                          <b-input v-model="receiver.name" type="text" :placeholder="$t('name')"></b-input>
                        </b-field>
                        <b-field>
                          <b-input v-model="receiver.secondSurname" type="text" :placeholder="$t('second_sur_name')"></b-input>
                        </b-field>
                      </div>
                      <div class="column is-half">
                        <b-field>
                          <b-input v-model="receiver.phoneNumber" type="text"
                                   :placeholder="$t('phoneRecipient')"></b-input>
                        </b-field>

                        <b-field>
                          <b-input v-model="receiver.lastName" type="text" :placeholder="$t('last_name1')"></b-input>
                        </b-field>
                        <b-field custom-class="active-btn">
                          <b-switch :value="false"
                                    v-model="receiver.isActive"
                                    :left-label="true"
                                    size="is-medium"
                                    type="is-green-success">
                            {{ $t('active') }}
                          </b-switch>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-full">
                        <b-field>
                          <multiselect
                              :placeholder="`${$t('card_type')}`"
                              v-model="breachBank"
                              track-by="brand"
                              :custom-label="selectName"
                              :searchable="true"
                              open-direction="bottom"
                              :show-no-results="false"
                              :multiple="true"
                              :options="breachBankList">
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span> <br>
                                <span class="option__small">{{ props.option.prefix }}</span></div>
                            </template>
                          </multiselect>
                        </b-field>
                        <b-field v-for="(item, idx) in availableCard" :key="idx" :label="item.name"
                                 label-position="inside">
                          <b-input v-model="cards[item.brand]" custom-class="full-input" type="text"
                                   placeholder="Tarjeta de débito"></b-input>
                        </b-field>
                      </div>
                    </div>

                  </b-tab-item>
                  <b-tab-item v-if="true">
                    <template #header>
                      <span class="tag is-pink-light mx-10 rounded-10">Domicilio</span>
                    </template>
                    <div class="columns">
                      <div class="column">
                        <b-field>
                          <multiselect
                              :placeholder="`${$t('issuer')}`"
                              v-model="sender"
                              :custom-label="selectNameIssuer"
                              :searchable="true"
                              open-direction="bottom"
                              :options="senderList">
                          </multiselect>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field>
                          <b-input custom-class="full-input" v-model="receiver.name" type="text"
                                   :placeholder="$t('name')"></b-input>
                        </b-field>
                        <b-field>
                          <b-input custom-class="full-input" v-model="receiver.lastName" type="text" :placeholder="$t('last_name1')"></b-input>
                        </b-field>
                        <b-field>
                          <b-input custom-class="full-input" v-model="receiver.secondSurname" type="text" :placeholder="$t('second_sur_name')"></b-input>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-half">
                        <b-field>
                          <b-input v-model="receiver.phoneNumber" type="text" :placeholder="$t('phone')"></b-input>
                        </b-field>
                      </div>
                      <div class="column is-half">
                        <b-field custom-class="active-btn">
                          <b-switch :value="false"
                                    v-model="receiver.isActive"
                                    :left-label="true"
                                    size="is-medium"
                                    type="is-green-success">
                            {{ $t('active') }}
                          </b-switch>
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-full">
                        <label>
                          <textarea v-model="receiver.addressLine" class="textarea rounded-10" rows="2"
                                    :placeholder="$t('address')">
                          </textarea>
                        </label>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-half">
                        <b-field>
                          <multiselect
                              placeholder="Provincia"
                              v-model="province"
                              :custom-label="selectName"
                              :searchable="true"
                              open-direction="bottom"
                              :show-no-results="false"
                              :options="stateList">
                          </multiselect>
                        </b-field>
                      </div>
                      <div class="column is-half">
                        <b-field>
                          <b-input v-model="receiver.municipality" type="text" :placeholder="$t('city')"
                                   custom-class="full-input"></b-input>

                        </b-field>
                      </div>
                    </div>

                  </b-tab-item>
                </b-tabs>
              </b-tab-item>
              <b-tab-item>
                <template #header>
                  <span class="tag is-green-accent mx-10 rounded-10">Opcionales</span>
                </template>

                <div class="columns optionals">
                  <div class="column is-half">
                    <b-field>
                      <b-input v-model="receiver.alternativePhoneNumber" type="text"
                               placeholder="Teléfono Alternativo"></b-input>
                    </b-field>
                    <b-field>
                      <label>
                          <b-input v-model="receiver.repart" type="text" custom-class="full-input"
                                    :placeholder="$t('repart')">
                          </b-input>
                      </label>
                    </b-field>
                  </div>
                  <div class="column is-half">
                    <b-field>
                      <multiselect
                          placeholder="Provincia"
                          v-model="province"
                          :custom-label="selectName"
                          :searchable="true"
                          open-direction="bottom"
                          :show-no-results="false"
                          :options="stateList">
                      </multiselect>
                    </b-field>
                    <b-field>
                      <b-input custom-class="full-input" v-model="receiver.municipality" type="text"
                               :placeholder="$t('municipality')"></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-full">
                    <b-field>
                      <label>
                        <b-input v-model="receiver.addressLine" type="text" custom-class="full-input"
                                 :placeholder="$t('address')">
                        </b-input>
                      </label>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-half">
                    <b-field>
                      <multiselect
                          :placeholder="$t('personal_identification_type')"
                          v-model="identificationType"
                          :custom-label="setNameIdentificationType"
                          :searchable="true"
                          open-direction="bottom"
                          :options="identificationTypeList">
                      </multiselect>
                    </b-field>

                  </div>
                  <div class="column is-half">
                    <b-field>
                      <b-input v-model="receiver.personalIdentificationNumber" type="text"
                               :placeholder="$t('personal_identification_number')"></b-input>
                    </b-field>
                  </div>
                </div>
              </b-tab-item>
            </b-tabs>
          </div>
          <div class="buttons mt-40 mb-20 is-flex is-align-items-center is-justify-content-flex-end">
            <button @click="save" class="button save is-primary">Guardar</button>
          </div>
        </section>

      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Multiselect from "vue-multiselect";
import {CUSTOMER_TYPE} from "@/enum/customerType";
import {DOCUMENT_TYPE} from "@/enum/documentType";
import {exitsKey, getStorage, saveStorage} from "@/utils";
import {USER_TYPE} from "@/enum/userType";
import {BREACH_BANK} from "@/enum/branchBank";
import {CURRENCY} from "@/enum/currency";

export default {
  name: "modalNewRecipient",
  components: {Multiselect},
  data() {
    return {
      BREACH_BANK,
      breachBankList: Object.values(BREACH_BANK),
      availableCard: [],
      selectedCard: [],
      breachBank: null,
      cityList: [],
      stateList: [],
      senderList: [],
      sender: exitsKey('sender') ? getStorage('sender') : null,
      country: null,
      province: null,
      municipality: null,
      identificationType: null,
      post: true,
      cards: [],
      receiver: {
        card: [],
        name: null,
        lastName: null,
        secondSurname: null,
        personalIdentificationNumber: null,
        phoneNumber: null,
        addressLine: null,
        repart: null,
        municipality: null,
        alias: null,
        alternativeName: null,
        alternativePersonalIdentificationNumber: null,
        alternativePhoneNumber: null,
        alternativeLastName: null,
        province: null,
        email: null,
        personalIdentificationType: null,
        personalIdentificationExpirationDate: null,
        personalIdentificationIssueDate: null,
        customerType: CUSTOMER_TYPE.RECIPIENT,
        senderId: null,
        zipCode: null,
      }
    }
  },
  props: {
    selected: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: false
    },
    recipientId: {
      type: String
    },
    issuerId: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      identificationTypeList: 'GET_IDENTIFICATION_TYPE',
      lang: 'GET_LANG',
      user: 'GET_USER'
    })
  },
  methods: {
    ...mapActions({
      fetchState: 'FETCH_STATES_BY_COUNTRY',
      fetchCountry: 'FETCH_COUNTRY',
      fetchCuba: 'FETCH_CUBA',
      fetchCity: 'FETCH_CITY',
      fetchSender: 'FETCH_ISSUER',
      fetchRecipient: 'FETCH_RECIPIENT',
      saveRecipient: 'SAVE_CUSTOMER',
      editRecipient: 'EDIT_CUSTOMER',
      goBack: 'GO_BACK',
      goTo: 'GO_TO'
    }),
    save() {
      this.getCards().then(() => {
        if (this.post) {
          this.saveRecipient(this.receiver).then(value => {
            this.events(value)
          })
        } else {
          this.editRecipient(this.receiver).then((value) => {
            this.events(value)
          })
        }
      })

    },
    events(value) {
      this.receiver = {}
      this.$emit('receiver', value.id)
      this.$emit('close')
      this.$emit('reload')
      this.$emit('refresh')
      window.location.reload()
    },
    setNameIdentificationType({value}) {
      switch (value) {
        case DOCUMENT_TYPE.CI:
          return this.lang === 'es' ? 'Carnet de Identidad' : 'DNI';
        case DOCUMENT_TYPE.SOCIAL_SECURITY:
          return this.lang === 'es' ? 'Seguridad Social' : 'Social Security';
        case DOCUMENT_TYPE.PASSPORT_CARD_US:
          return this.lang === 'es' ? 'Tarjeta de Pasaporte' : 'Passport Card';
        case DOCUMENT_TYPE.PASSPORT:
          return this.lang === 'es' ? 'Pasaporte' : 'Passport';
        case DOCUMENT_TYPE.ID_OFFICIAL:
          return this.lang === 'es' ? 'ID Oficial' : 'ID Official';
        case DOCUMENT_TYPE.DRIVER_LICENSE:
          return this.lang === 'es' ? 'Licencia de Conduccion' : 'Drive Licence';
        case DOCUMENT_TYPE.ALIEN_CARD:
          return this.lang === 'es' ? 'Tarjeta de Extranjera' : 'Alien Card';

      }
    },
    selectNameIssuer({name, phoneNumber}) {
      return `(${phoneNumber})--${name}`
    },
    selectName({name}) {
      return name
    },
    getSenders(current = 1, record = 0, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchSender(data)
            .then(value => {
              resolve(value)
            })
      })
    },
    getRecipients(current = 1, record = 0, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchRecipient(data)
            .then(value => {
              resolve(value)
            })

      })
    },
    getCards() {
      this.receiver.card = []
      return new Promise(resolve => {
        for (const key in this.cards) {
          switch (key) {
            case 'bandec_mlc':
              this.receiver.card.push({
                cardNumber: this.cards[key],
                brand: 'bandec_mlc',
                currency: CURRENCY.MLC,
                isDefault: false
              })
              break;
            case 'bm_mlc':
              this.receiver.card.push({
                cardNumber: this.cards[key],
                brand: 'bm_mlc',
                currency: CURRENCY.MLC,
                isDefault: false
              })
              break;
            case 'bpa_mlc':
              this.receiver.card.push({
                cardNumber: this.cards[key],
                brand: 'bpa_mlc',
                currency: CURRENCY.MLC,
                isDefault: false
              })
              break;
            case 'bandec_cup':
              this.receiver.card.push({
                cardNumber: this.cards[key],
                brand: 'bandec_mlc',
                currency: CURRENCY.MLC,
                isDefault: false
              })
              break;
            case 'bm_cup':
              this.receiver.card.push({
                cardNumber: this.cards[key],
                brand: 'bm_mlc',
                currency: CURRENCY.MLC,
                isDefault: false
              })
              break;
            case 'bpa_cup':
              this.receiver.card.push({
                cardNumber: this.cards[key],
                brand: 'bpa_mlc',
                currency: CURRENCY.MLC,
                isDefault: false
              })
              break;
          }
        }
        resolve(true)
      })

    }
  },
  watch: {
    breachBank: function () {
      this.availableCard = this.breachBank;
    },
    selected: function () {
      saveStorage('sender', this.selected)
    },
    'sender': function () {
      this.receiver.senderId = this.sender.id
      if (this.user.userType === USER_TYPE.MANAGER) {
        saveStorage('sender', this.sender)
      }
    },
    'province': async function () {
      this.receiver.province = this.province.name
      this.cityList = await this.fetchCity(this.province.id)
    },
    'municipality': function () {
      this.receiver.municipality = this.municipality.name
    },
    'identificationType': function () {
      this.receiver.personalIdentificationType = this.identificationType.value
    },
  },
  mounted() {
    if (this.issuerId) {
      this.getSenders(1, 1, this.issuerId).then(value => {
        this.sender = value
        this.senderList.push(value)
      })
    } else {
      this.getSenders().then(value => {
        this.senderList = value.data
      })
    }
  },
  created() {
    if (this.selected) {
      this.sender = this.selected
    }
    if (this.issuerId) {
      this.getSenders(1, 1, this.issuerId).then(value => {
        this.sender = value
        this.senderList.push(value)
      })
    } else {
      this.getSenders().then(value => {
        this.senderList = value.data
      })
    }

    this.fetchCuba().then(async (value) => {
      this.country = value[0]
      this.stateList = await this.fetchState(this.country.id)
      if (this.edit && this.recipientId) {
        this.post = false
        this.getRecipients(1, 1, this.recipientId).then(value => {
          this.receiver = value
          this.receiver.zipCode = value.address.zipcode
          this.receiver.email = value.address.email
          this.receiver.addressLine = value.address.line1
          this.receiver.personalIdentificationNumber = value.identityDocument.documentNumber
          this.identificationType = this.identificationTypeList.find(f => f.value === value.identityDocument.documentTypeOption)
          this.province = this.stateList.find(s => s.name === value.address.stateOrProvince)
          this.getSenders(1, 1, value.senderId)
              .then(sender => {
                this.sender = sender
              })
          this.receiver.municipality = value.address.cityOrMunicipality
          // this.fetchCity(this.province.id)
          //     .then(cities => {
          //       this.receiver.municipality = cities.find(c => c.name === value.address.cityOrMunicipality)
          //     })
        })

      }

    })
  }
}
</script>

<style scoped lang="scss">
.tag {
  width: 111px;
  height: 24px;
  font-weight: bold;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #FFFFFF;
}

.is-transfer {
  font-weight: bold;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #FDC4D1;
  border: 1px solid #FDC4D1;
}

</style>